import React from 'react';
import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2.5px); 
  }
  100% {
    transform: translateY(-4px); 
  }
`;

const Container = styled.div`
  z-index: 999;
  justify-contents: center;
  margin: 1rem;
`;

const Dot = styled.span`
  display: inline-block;
  margin-right: 4px;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  animation: ${waveAnimation} 1.5s infinite alternate;
`;

const LoadingIndicator = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <Container>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 900 }}>Sabrina Responding</span>
            <div>
              <Dot />
              <Dot style={{ animationDelay: '0.5s' }} />
              <Dot style={{ animationDelay: '0.8s' }} /></div>
          </div>
        </Container>
      )}
    </>
  );
};

export default LoadingIndicator;
