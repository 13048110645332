import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StudySpace from './StudySpace';
import PDFDialogue from './PDFDialogue';
import TextEditor from './TextEditor';

const StudySpaceRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<StudySpace />} />
      <Route path="/pdf-dialogue/:fileId" element={<PDFDialogue />} />
      <Route path="/text-editor/:documentId" element={<TextEditor />} />
    </Routes>
  );
};

export default StudySpaceRoutes;