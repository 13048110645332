import { UserProfile } from '@clerk/clerk-react';
import './UserProfile.css'

const ProfilePage = () => {
  return (
    <div style={{ padding: 0, margin: 0 }}>
      {/* <h2>Profile</h2> */}
      <UserProfile />
    </div>
  );
};

export default ProfilePage;