import React, { useState } from 'react';
import { FilePdfOutlined, UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UploadPdfSVG, GearSVG, TextEditorSVG } from '../../components/svgs/ButtonIconsSvgs';
import './StudySpace.css'

const StudySpace = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const { user } = useUser();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();


    const openTextEditor = (documentId) => {
        navigate(`/dashboard/study-space/text-editor/${documentId}`);
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('pdf_file', pdfFile);
        console.log(pdfFile)
        setIsUploading(true);


        axios.post(`${apiBaseUrl}/upload-pdf?userId=${user.id}`, formData)
            .then(response => {
                console.log('Upload successful:', response.data);
                // Navigate to PDF viewer upon successful upload
                navigate(`/dashboard/study-space/pdf-dialogue/${response.data.objectId}`);
            })
            .catch(error => {
                console.error('Error uploading PDF:', error);
                message.error('Error uploading PDF!');
            })
            .finally(() => {
                setIsUploading(false);
                setPdfFile(null);
            });
    };

    const uploadButton = (
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{
                width: '100%',
                height: '100%',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '1rem',
            }}>
                <CloudUploadOutlined style={{ fontSize: 40, color: '#808080' }} />
                <span style={{ fontFamily: 'Epilogue', fontSize: '18px', fontWeight: 600 }}>Drag And Drop PDF File Here</span>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                margin: '1rem 0',
            }}>
                <hr style={{ width: '45%', borderTop: '1px solid #808080' }} />
                <span style={{ fontWeight: 600, padding: '0 1rem' }}>OR</span>
                <hr style={{ width: '45%', borderTop: '1px solid #808080' }} />
            </div>
            <div>
                <button style={{
                    width: '100%',
                    height: '100%',
                    padding: '1rem',
                    borderRadius: '4px',
                    borderStyle: 'dashed',
                    borderWidth: '1px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                }}>
                    <UploadOutlined style={{ fontSize: 40, color: '#808080' }} />
                    <span style={{ fontFamily: 'Epilogue', fontSize: '18px', fontWeight: 600 }}>Select PDF to Upload</span>
                </button>
            </div>
        </div>
    );


    const props = {
        name: "pdf",
        accept: ".pdf",
        className: "pdf-uploader",
        showUploadList: false,
        beforeUpload: (file) => {
            const isPdf = file.type === 'application/pdf';
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isPdf) {
                message.error('You can only upload PDF files!');
                return false;
            }
            if (!isLt5M) {
                message.error('File must be smaller than 5MB!');
                return false;
            }

            setPdfFile(file);
            return false;
        }
    };

    const createDocument = async () => {
        const userId = user.id;
        try {
            const response = await axios.post(`${apiBaseUrl}/save-text-editor-content?userId=${userId}`, { name: 'Untitled', editor_content: "" });
            console.log(response.data)
            const documentId = response.data.id;
            // setDocumentId(documentId);
            openTextEditor(documentId);

        } catch (error) {
            console.error('Error saving document:', error);
        }
    };

    return (
        <div style={{ textAlign: 'left', height: '100%' }}>
            <h1 style={{ marginBottom: '2rem' }}>Study Space</h1>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: '2rem 0',
                gap: '3rem',
            }}
            >

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, padding: '3rem 1rem', background: '#f5f5f5', borderRadius: '20px', border: '1px solid #ccc', height: '300px', minWidth: '300px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', margin: 'auto' }}>
                        <div>
                            <Upload
                                {...props}
                                maxCount={1}
                            >
                                {pdfFile ? (
                                    <button style={{
                                        padding: '1rem',
                                        borderRadius: '4px',
                                        borderStyle: 'dashed',
                                        borderWidth: '1px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        gap: '1rem',
                                        marginBottom: '1rem'
                                    }}>
                                        <FilePdfOutlined style={{ fontSize: 40, color: '#808080' }} /> <span>{pdfFile.name}</span>
                                    </button>

                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>
                        <div>
                            {/* Upload Pdf button */}
                            {pdfFile && !isUploading && (
                                <button style={{
                                    padding: '1rem',
                                    borderRadius: '4px',
                                    borderStyle: 'dashed',
                                    borderWidth: '1px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px'
                                }} onClick={handleUpload}>
                                    <UploadPdfSVG />
                                    <span style={{ fontFamily: 'Epilogue', fontSize: '12px' }}>Sabrina’s Knowledge Voyage</span>
                                </button>
                            )}
                        </div>

                        <div>
                            {/* Uploading indicator */}
                            {isUploading &&
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '1rem',
                                    borderRadius: '4px',
                                    borderStyle: 'dashed',
                                    borderWidth: '1px',
                                    justifyContent: 'center',
                                }}>
                                    <p style={{ fontFamily: 'Epilogue', marginRight: '10px', color: '#000' }}>Sabrina is turning the gears on your documents</p>
                                    <div><GearSVG className="gear-icon" /></div>
                                </div>

                            }
                        </div></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, padding: '3rem 1rem', height: '', background: '#f5f5f5', borderRadius: '20px', border: '1px solid #ccc' }}>
                    <button
                        onClick={createDocument}
                        style={{
                            padding: '3rem 5rem',
                            borderRadius: '4px',
                            borderStyle: 'dashed',
                            borderWidth: '1px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                            flexDirection: 'column'
                        }}
                    >
                        <TextEditorSVG />
                        <span style={{ fontFamily: 'Epilogue', fontSize: '18px', fontWeight: 600 }}>Edit Text Document</span>
                    </button>
                </div>

            </div>
        </div>

    );
};

export default StudySpace;