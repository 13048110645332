// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './views/Auth/Login';
import SignUp from './views/Auth/SignUp';
import ForgotPassword from './views/Auth/ForgotPassword';
import { Dashboard } from './views/Dashboard/Dashboard';
import NotFoundPage from './views/public/NotFoundPage';
import Home from './views/public/Home';
import Terms from './views/public/Terms';
import Privacy from './views/public/Privacy';
import ProtectedRoute from './utils/ProtectedRoute';
import { ClerkProvider } from '@clerk/clerk-react';

const App = () => {

  const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

  if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
  }

  return (
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
    >
      <Router>
        <Routes>
          {/* public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="/auth/*" element={
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Routes>
          } />
          <Route path="/dashboard/*" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ClerkProvider>
  );
};

export default App;