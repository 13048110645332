import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import NavBar from "./NavBar";
import Footer from "./Footer";
import './Terms.css';
import { Link } from "react-router-dom";

const Terms = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
                style={{ color: '#0000FF', fontSize: '18px' }}
            >
                {label}
            </Link>
        );
    };

    return (
        <>
            <NavBar SignedIn={SignedIn} SignedOut={SignedOut} />
            <div className="terms-section">
                <div className="terms-section-warpper">
                    <h1 className='white-color-text-h1-tag'>
                        Terms for using Education Re-Imagined services
                    </h1>

                    <h4 className='white-color-text-h4-tag'>
                        Welcome to HLINA, a web portal that provides you with access to various artificial
                        intelligence services and tools. By using our website, you agree to be bound by these
                        terms of service. Please read them carefully before you start using our services.
                    </h4>
                    <h3 className='white-color-text-h3-tag'>
                        1. Acceptance of Terms
                    </h3>
                    <p className='white-color-text-p-tag'>
                        These terms of service (&quot;Terms&quot;) govern your access to, and use of the website,
                        services, and tools (collectively, the &quot;Services&quot;) provided by [Education Re-Imagined], a
                        company registered in the United States of America (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;).
                        By accessing or using the Services, you confirm that you have read, understood, and
                        agree to be bound by these Terms, and that you are at least 18 years of age or have
                        obtained the consent of your parents or legal guardian if you are under 18. If you do not
                        agree to these Terms, you must not access or use the Services.
                        We may change or update these Terms from time to time at our sole discretion. We will
                        notify you of any material changes by posting the updated Terms on our website and
                        indicating the date of the last revision. Your continued access or use of the Services
                        after the effective date of the revised Terms constitutes your acceptance of the new
                        Terms. If you do not agree to the revised Terms, you must stop accessing or using the
                        Services.
                    </p>

                    <h3 className='white-color-text-h3-tag'>
                        2. Description of Services
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We provide you with access to various artificial intelligence services and tools, such as
                        natural language processing, computer vision, data analysis, and machine learning. You
                        can use the Services for your personal or professional purposes, subject to these Terms
                        and any applicable laws and regulations.
                        We reserve the right to modify, suspend, or discontinue the Services or any part thereof
                        at any time, with or without notice, for any reason. We will not be liable to you or any
                        third party for any modification, suspension, or discontinuation of the Services or any
                        part thereof.
                        We do not guarantee that the Services will be available, uninterrupted, secure, or error-
                        free, or that the Services will meet your requirements or expectations. We do not
                        warrant the accuracy, completeness, or reliability of the information, content, or results

                        obtained through the Services. You acknowledge and agree that your use of the
                        Services is at your own risk and discretion.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        3. User Accounts and Registration
                    </h3>
                    <p className='white-color-text-p-tag'>
                        In order to access or use some of the Services, you may need to create a user account
                        (&quot;Account&quot;) and provide us with certain information, such as your name, email address,
                        password, and payment details. You agree to provide us with accurate, complete, and
                        current information, and to update your information as necessary. You are responsible
                        for maintaining the security and confidentiality of your Account and password, and for
                        any activity that occurs under your Account. You agree to notify us immediately of any
                        unauthorized access or use of your Account or password, or any other breach of
                        security.
                        We may suspend or terminate your Account or access to the Services at any time, with
                        or without notice, for any reason, including but not limited to: (a) your violation of these
                        Terms or any applicable laws or regulations; (b) your misuse or abuse of the Services;
                        (c) your failure to pay any fees or charges due to us; or (d) your inactivity for a
                        prolonged period of time. We will not be liable to you or any third party for any
                        suspension or termination of your Account or access to the Services.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        4. User Content and Conduct
                    </h3>
                    <p className='white-color-text-p-tag'>
                        Some of the Services may allow you to upload, submit, store, send, receive, or share
                        data, text, images, videos, audio, or other materials or information (&quot;User Content&quot;). You
                        retain ownership of your User Content, and you grant us a non-exclusive, worldwide,
                        royalty-free, transferable, and sublicensable license to use, copy, modify, distribute,
                        display, and perform your User Content in connection with the Services and our
                        business, subject to your privacy settings and preferences. You represent and warrant
                        that you have the right to grant us this license, and that your User Content does not
                        infringe or violate the rights of any third party, including but not limited to intellectual
                        property, privacy, or publicity rights.
                        You are solely responsible for your User Content and your use of the Services.
                        We do not monitor, review, or endorse any User Content, and we have no obligation to
                        do so. However, we reserve the right to remove, edit, or block any User Content at any
                        time, with or without notice, for any reason, at our sole discretion. We also reserve the
                        right to disclose any User Content or user information to law enforcement authorities or
                        other third parties, if we believe that such disclosure is necessary or appropriate to
                        comply with legal obligations, protect our rights or property, or prevent or stop any illegal
                        or harmful activity.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        5. Intellectual Property Rights
                    </h3>
                    <p className='white-color-text-p-tag'>
                        The Services and all related content, features, and functionality, including but not limited
                        to software, code, design, graphics, logos, icons, images, videos, audio, text, data, and
                        trademarks, are the property of [Education Re-Imagined] or its licensors, and are
                        protected by United States and international intellectual property laws. You may not
                        copy, modify, reproduce, distribute, display, or perform any part of the Services or
                        related content, features, and functionality, without our prior written consent. You may
                        not reverse engineer, decompile, or disassemble any part of the Services or related
                        content, features, and functionality, or attempt to derive or extract any source code or
                        trade secrets from them. You may not use any automated means, such as bots,
                        crawlers, scrapers, or spiders, to access or use the Services or related content,
                        features, and functionality, for any purpose. You may not use the Services or related
                        content, features, and functionality for any commercial purpose, or in any manner that
                        competes with or harms us or our licensors.
                        We grant you a limited, non-exclusive, non-transferable, and revocable license to
                        access and use the Services for your personal or professional purposes, subject to
                        these Terms and any applicable laws and regulations. This license does not grant you
                        any right, title, or interest in the Services or related content, features, and functionality,
                        or any intellectual property rights therein. We reserve all rights not expressly granted to
                        you in these Terms.
                        6. Fees and Payments
                        Some of the Services may require you to pay fees or charges to us or our third-party
                        service providers, such as subscription fees, usage fees, or transaction fees. You agree
                        to pay all applicable fees or charges in accordance with the payment terms and
                        conditions that we or our third-party service providers may provide to you. You authorize
                        us or our third-party service providers to charge your chosen payment method for the
                        fees or charges due. You are responsible for providing valid and current payment
                        information, and for updating it as necessary. You are also responsible for paying any
                        taxes, duties, or levies that may apply to your use of the Services.
                        We may change or update the fees or charges for the Services at any time, with or
                        without notice, for any reason. We will notify you of any material changes by posting the
                        updated fees or charges on our website and indicating the date of the last revision. Your
                        continued use of the Services after the effective date of the revised fees or charges
                        constitutes your acceptance of the new fees or charges. If you do not agree to the
                        revised fees or charges, you must stop using the Services.

                        If you fail to pay any fees or charges due to us or our third-party service providers, we
                        may suspend or terminate your access to the Services or any part thereof, or take any
                        other legal action that we deem appropriate. We will not be liable to you or any third
                        party for any suspension or termination of your access to the Services or any part
                        thereof, or any other legal action that we take, due to your failure to pay any fees or
                        charges due.
                    </p>

                    <h3 className='white-color-text-h3-tag'>
                        6. Privacy and Data Protection
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We respect your privacy and data protection rights, and we are committed to protecting
                        your personal information. Please refer to our Privacy Policy, which is incorporated into
                        these Terms by reference, for more information on how we collect, use, share, and
                        protect your personal information when you use the Services.
                        By using the Services, you consent to our collection, use, and sharing of your personal
                        information as described in our Privacy Policy. You also agree to comply with all
                        applicable laws and regulations regarding privacy and data protection when you use the
                        Services, and to respect the privacy and data protection rights of other users and third
                        parties.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        7. Disclaimer of Warranties
                    </h3>
                    <p className='white-color-text-p-tag'>
                        THE SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS,
                        WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                        INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS
                        FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR ACCURACY. WE DO
                        NOT WARRANT THAT THE SERVICES WILL BE AVAILABLE, UNINTERRUPTED,
                        SECURE, OR ERROR-FREE, OR THAT THE SERVICES WILL MEET YOUR
                        REQUIREMENTS OR EXPECTATIONS. WE DO NOT WARRANT THE ACCURACY,
                        COMPLETENESS, OR RELIABILITY OF THE INFORMATION, CONTENT, OR
                        RESULTS OBTAINED THROUGH THE SERVICES. WE DO NOT WARRANT THAT
                        THE SERVICES OR RELATED CONTENT, FEATURES, AND FUNCTIONALITY WILL
                        BE FREE OF VIRUSES, MALWARE, OR OTHER HARMFUL COMPONENTS. YOU
                        ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR
                        OWN RISK AND DISCRETION.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        8. Limitation of Liability
                    </h3>
                    <p className='white-color-text-p-tag'>
                        TO THE FULLEST EXTENT PERMITTED BY LAW, WE AND OUR AFFILIATES,
                        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS, AND SERVICE
                        PROVIDERS WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY

                        DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE
                        DAMAGES, OR ANY LOSS OF PROFITS, REVENUE, DATA, OR GOODWILL,
                        ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE
                        THE SERVICES, OR YOUR RELIANCE ON ANY INFORMATION, CONTENT, OR
                        RESULTS OBTAINED THROUGH THE SERVICES, WHETHER BASED ON
                        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF
                        WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
                        EVENT WILL OUR TOTAL LIABILITY TO YOU OR ANY THIRD PARTY FOR ALL
                        CLAIMS RELATING TO THE SERVICES EXCEED THE AMOUNT OF FEES OR
                        CHARGES THAT YOU HAVE PAID TO US FOR THE USE OF THE SERVICES IN THE 12 MONTHS PRECEDING THE CLAIM.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        9. Indemnification
                    </h3>
                    <p className='white-color-text-p-tag'>
                        You agree to indemnify, defend, and hold harmless us and our affiliates, directors,
                        officers, employees, agents, licensors, and service providers from and against any and
                        all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable
                        attorneys&#39; fees) arising out of or relating to: (a) your use of or inability to use the
                        Services; (b) your violation of these Terms or any applicable laws or regulations; (c)
                        your User Content or your infringement or violation of the rights of any third party; or (d)
                        your negligence or misconduct. We reserve the right to assume the exclusive defense
                        and control of any matter that you are required to indemnify us for, and you agree to
                        cooperate with our defense of such claims. You agree not to settle any such claim
                        without our prior written consent.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        10. Governing Law and Jurisdiction
                    </h3>
                    <p className='white-color-text-p-tag'>
                        These Terms and your use of the Services are governed by and construed in
                        accordance with the laws of the State of Delaware, without regard to its conflict of laws
                        principles. You agree to submit to the exclusive jurisdiction and venue of the state and
                        federal courts located in Delaware for any dispute arising out of or relating to these
                        Terms or your use of the Services, and you waive any objection to such jurisdiction and
                        venue.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        11. Arbitration and Class Action Waiver

                    </h3>
                    <p className='white-color-text-p-tag'>
                        Any dispute, claim, or controversy arising out of or relating to these Terms or your use of the Services, except for disputes relating to our or our licensors' intellectual property rights, will be resolved by binding arbitration, rather than in court, unless you opt out of arbitration within 30 days of the date you first agree to these Terms by sending a written notice to Education Re-Imagined, Attn: 333 12th St, Unit 305, San Francisco, CA 94103, USA, that includes your name, address, account information, and a clear statement that you want to opt out of arbitration. Arbitration will be conducted by the American Arbitration Association (AAA) under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes. The arbitration will be held in the county where you reside or at another mutually agreed location. If the value of the relief sought is $10,000 or less, you or we may elect to have the arbitration conducted by telephone or based solely on written submissions, which election will be binding on you and us subject to the arbitrator's discretion to require an in-person hearing. The arbitrator will have the authority to award any remedies that would be available in court, and the arbitrator's decision will be final and binding on both parties. The arbitrator will not have the authority to conduct any class, collective, or representative proceeding, and you and we agree to waive any right to participate in any such proceeding.
                        You and we agree that any claim or cause of action arising out of or relating to these Terms or your use of the Services must be brought within one year after the claim or cause of action arises, or otherwise be barred forever.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        12. General Provisions
                    </h3>
                    <p className='white-color-text-p-tag'>
                        These Terms constitute the entire agreement between you and us regarding your use of
                        the Services, and supersede any prior or contemporaneous agreements,
                        communications, or understandings, whether oral or written, between you and us. If any
                        provision of these Terms is held to be invalid, illegal, or unenforceable by a court of
                        competent jurisdiction, such provision will be modified to the extent necessary to make it
                        enforceable, or if not possible, will be severed from these Terms, and the remaining
                        provisions will remain in full force and effect. Our failure or delay to exercise or enforce
                        any right or remedy under these Terms will not constitute a waiver of such right or
                        remedy. We may assign or transfer our rights and obligations under these Terms to any
                        person or entity, without your consent or notice. You may not assign or transfer your
                        rights and obligations under these Terms to any person or entity, without our prior
                        written consent. These Terms are binding on and inure to the benefit of you and us and
                        our respective successors and assigns.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        13. Contact Us
                    </h3>
                    <p className='white-color-text-p-tag'>
                        If you have any questions, comments, or concerns about these Terms or the Services,
                        please <span> <ButtonMailto label="contact us" mailto="mailto:contactus@education21forward.com" /> </span>. We will respond to your inquiries as soon as
                        possible.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Terms;
