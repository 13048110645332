import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const ScrollButton = ({ direction, onClick, disabled }) => {
  const buttonStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    background: disabled ? '#ccc' : '#fff', 
    border: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {direction === 'left' ? <FaAngleLeft style={{ fontSize: '24px' }} /> : <FaAngleRight style={{ fontSize: '24px' }} />}
    </button>
  );
};

export default ScrollButton;
