import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { FaComment } from 'react-icons/fa';
import ChatPopup from '../../components/ChatPopup';
import styled from 'styled-components';
import WorkRoomChatInterface from '../../components/WorkRoomChatInterface'
import './PDFDialogue.css';
import { Tooltip, Alert, Typography, Button } from 'antd';
import PDFViewer from './PdfViewer';
import LoadingSpin from '../../components/LoadingSpin';

const PDFDialogue = () => {
  const { fileId } = useParams();
  const { user } = useUser();
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [showChat, setShowChat] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [retrievedMessage, setRetrievedMessage] = useState('');
  const [conversations, setConversations] = useState([])
  const [responseAlert, setResponseAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [isResponding, setIsResponding] = useState(false);
  const [retry, setRetry] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {

    setIsLargeScreen(window.innerWidth > 768);

    // const handleResize = () => {
    //   setIsLargeScreen(window.innerWidth > 768);
    // };

    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);

  useEffect(() => {
    let timer;
    if (selectedText !== "") {
      timer = setTimeout(() => {
        setSelectedText("");
      }, 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [selectedText]);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get-pdf/${fileId}?userId=${user.id}`);
        setPdfData(response.data);
      } catch (error) {
        setError('Error fetching PDF data');
      } finally {
        setLoading(false);
      }
    };

    fetchPdfData();
    return () => {
    };
  }, [fileId, user.id, apiBaseUrl]);

  if (loading) {
    return (
      <>
        <LoadingSpin />
      </>
    );
  }

  const handleRetry = () => {
    setError(null);
    setRetry(retry + 1);
  };

  if (error) {
    return (
      <div
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Typography.Text type="danger">{error}</Typography.Text>
        <Button type="primary" onClick={handleRetry} style={{ marginLeft: 8 }}>
          Retry
        </Button>
      </div>
    );
  }

  const pdfURL = pdfData.presignedUrl;
  console.log(pdfURL)

  const CustomTooltip = ({ title, ...props }) => (
    <Tooltip
      {...props}
      title={<span style={{ color: '#fff', fontSize: '12px', fontFamily: 'Inter', fontWeight: 'bold' }}>{title}</span>}
    />
  );

  const handleFollowUpClick = (followUpText) => {

    setRetrievedMessage(followUpText, () => {
      try {
        handleRetrieve();
      } catch (error) {
        console.error('Error retrieving question:', error);
        setResponseAlert(
          <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
            message="An error occurred!" type="error"
            showIcon
            closable
            onClose={() => setResponseAlert(null)}
          />
        );

        setTimeout(() => setResponseAlert(null), 5000);
      }
    });
  };

  const handleRetrieve = async () => {
    const userId = user.id;
    const UserName = user.firstName;
    const AIName = 'Sabrina';


    setIsResponding(true);
    let formData = new FormData();

    formData.append('query', retrievedMessage);

    try {
      const response = await axios.post(`${apiBaseUrl}/retrieve/${fileId}?user_id=${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      setConversations([
        ...conversations,
        {
          type: 'retrievalChat',
          messages: [
            { text: retrievedMessage, sender: { name: { UserName }, type: 'User' }, type: 'retrievalChat' },
            { text: response.data.message, sender: { name: { AIName }, type: 'AI' }, type: 'retrievalChat' }
          ]
        }
      ]);

    } catch (error) {
      console.error('Error retrieving question:', error);
      setResponseAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="An error occurred during question retrieval!" type="error"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
    } finally {
      setIsResponding(false)
      setRetrievedMessage('')
    }
  };

  const handleQuickButtonClick = async (actionFunction) => {
    if (selectedText.length > 2000) {
      setErrorAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Your text is too long! Please shorten it to less than 2000 characters!" type="error"
          showIcon
          closable
          onClose={() => setErrorAlert(null)}
        />
      );

      setTimeout(() => setErrorAlert(null), 5000);
    } else {
      setErrorAlert(null);
      await actionFunction();
    }
  };

  const getKeyPoints = async () => {
    const UserId = user.id;
    const UserName = user.firstName;
    const AIName = 'Sabrina';

    setIsResponding(true);

    try {
      const response = await axios.post(`${apiBaseUrl}/generate_key_points/${fileId}?userId=${UserId}`, { text: selectedText });
      setConversations([
        ...conversations,
        {
          type: 'KeyPoints',
          messages: [
            { text: selectedText, sender: { name: { UserName }, type: 'User' }, type: 'KeyPoints' },
            { text: response.data.keyPoints, sender: { name: { AIName }, type: 'AI' }, type: 'KeyPoints' }
          ]
        }
      ]);

      console.log(conversations);

      setSelectedText("")

    } catch (error) {
      console.error('Error getting key points:', error);
      setErrorAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Error getting key points!" type="error"
          showIcon
          closable
          onClose={() => setErrorAlert(null)}
        />
      );

      setTimeout(() => setErrorAlert(null), 5000);
    } finally {
      setIsResponding(false);
    }
  };

  const getSummary = async () => {
    const UserId = user.id;
    const UserName = user.firstName;
    const AIName = 'Sabrina';

    setIsResponding(true);
    try {
      const response = await axios.post(`${apiBaseUrl}/summarize_text/${fileId}?userId=${UserId}`, { text: selectedText });
      setConversations([
        ...conversations,
        {
          type: 'summary',
          messages: [
            { text: selectedText, sender: { name: { UserName }, type: 'User' }, type: 'summary' },
            { text: response.data.summary, sender: { name: { AIName }, type: 'AI' }, type: 'summary' }
          ]
        }
      ]);

      setSelectedText("")
    } catch (error) {
      console.error('Error getting summary:', error);
      setErrorAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Error getting summary!" type="error"
          showIcon
          closable
          onClose={() => setErrorAlert(null)}
        />
      );

      setTimeout(() => setErrorAlert(null), 5000);
    } finally {
      setIsResponding(false);
    }
  };

  const getExplanation = async () => {
    const UserId = user.id;
    const UserName = user.firstName;
    const AIName = 'Sabrina';

    setIsResponding(true);
    try {
      const response = await axios.post(`${apiBaseUrl}/explain_text/${fileId}?userId=${UserId}`, { text: selectedText });
      setConversations([
        ...conversations,
        {
          type: 'explanation',
          messages: [
            { text: selectedText, sender: { name: { UserName }, type: 'User' }, type: 'explanation' },
            { text: response.data.explanation, sender: { name: { AIName }, type: 'AI' }, type: 'explanation' }
          ]
        }
      ]);

      setSelectedText("")
    } catch (error) {
      console.error('Error getting explanation:', error);
      setErrorAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Error getting explanation!" type="error"
          showIcon
          closable
          onClose={() => setErrorAlert(null)}
        />
      );

      setTimeout(() => setErrorAlert(null), 5000);
    } finally {
      setIsResponding(false);
    }
  };

  const ChatIcon = styled(FaComment)`
  font-size: 2rem;
  color: #0000FF;
  cursor: pointer;  
`;

  const handleCommentClick = () => {
    setShowChat(true);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCloseClick = () => {
    setShowChat(false);
  };

  const copyResponse = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Content copied to clipboard');
      setResponseAlert(
        <Alert
          style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Response is copied"
          type="success"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);

    } catch (err) {
      console.error('Failed to copy: ', err);
      setResponseAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Failed to copy response" type="error"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );
      setTimeout(() => setResponseAlert(null), 5000);
    }
  };

  const saveKeyPoints = async (textContent, fileName) => {
    const userId = user.id;
    try {
      const response = await axios.post(`${apiBaseUrl}/save-key-points?userId=${userId}`, {
        text_content: textContent,
        file_name: fileName
      });

      setResponseAlert(
        <Alert
          style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Successfull saved your notes"
          type="success"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );
      setTimeout(() => setResponseAlert(null), 5000);


    } catch (error) {
      setResponseAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="An error occurred during saving" type="error"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );
      setTimeout(() => setResponseAlert(null), 5000);
      throw error;
    }
  };

  function handleTextCopy(text) {
    setSelectedText(text);
  }

  // 
  const toggleChat = () => {
    setIsLargeScreen(prevState => !prevState);
  };


  return (
    <>
      <div className="work-room-container">
        <div className='pdf-viewer-warpper' style={{ height: "100%", boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '' }}>
          <div style={{ width: '100%', height: "100%", overflowY: "scroll", scrollbarWidth: 'thin', msOverflowStyle: 'none', }} >
            <PDFViewer pdfURL={pdfURL} onTextCopy={handleTextCopy} />
          </div>
        </div>


        {/* Chat Interface*/}
        {/* <WorkRoomChatInterface
          user={user}
          errorAlert={errorAlert}
          responseAlert={responseAlert}
          saveKeyPoints={saveKeyPoints}
          pdfData={pdfData}
          isResponding={isResponding}
          selectedText={selectedText}
          handleQuickButtonClick={handleQuickButtonClick}
          getKeyPoints={getKeyPoints}
          getSummary={getSummary}
          getExplanation={getExplanation}
          conversations={conversations}
          retrievedMessage={retrievedMessage}
          setRetrievedMessage={setRetrievedMessage}
          handleRetrieve={handleRetrieve}
          handleFollowUpClick={handleFollowUpClick}
          copyResponse={copyResponse}
        /> */}

        {/* Chat Interface - Slide from the right */}
        {isLargeScreen && (
          <WorkRoomChatInterface
            user={user}
            errorAlert={errorAlert}
            responseAlert={responseAlert}
            saveKeyPoints={saveKeyPoints}
            pdfData={pdfData}
            isResponding={isResponding}
            selectedText={selectedText}
            handleQuickButtonClick={handleQuickButtonClick}
            getKeyPoints={getKeyPoints}
            getSummary={getSummary}
            getExplanation={getExplanation}
            conversations={conversations}
            retrievedMessage={retrievedMessage}
            setRetrievedMessage={setRetrievedMessage}
            handleRetrieve={handleRetrieve}
            handleFollowUpClick={handleFollowUpClick}
            copyResponse={copyResponse}
          />
        )}
      </div>
      {/*  */}

      {/* Toggle button for Chat Interface */}
      <button className="toggle-chat-btn" onClick={toggleChat}>
        {isLargeScreen ? 'Hide Sabrina' : 'Ask Sabrina'}
      </button>

      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "990",
        }}
      >
        <CustomTooltip
          title="Chat with your buddies"
          placement="left">
          {!showChat && <ChatIcon onClick={handleCommentClick} />}
        </CustomTooltip>

        {showChat && (
          <ChatPopup
            user={user}
            onClose={handleCloseClick}
          />
        )}
      </div>
    </>
  );
};

export default PDFDialogue;


