import React from "react";
import { useState, useEffect } from "react";
import { useSignIn, useUser } from "@clerk/clerk-react";
import { useNavigate, Link } from "react-router-dom";

export default function SignInForm() {
  const { isLoaded, signIn, setActive } = useSignIn();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isSignedIn } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    if (isSignedIn) {
      navigate("/dashboard");
    }
  }, [isSignedIn, navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    setLoading(true);
    try {
      const result = await signIn.create({
        identifier: emailAddress,
        password,
      });

      if (result.status === "complete") {
        console.log(result);
        await setActive({ session: result.createdSessionId });
        navigate("/dashboard");
      } else {
        console.log(result);
      }
    } catch (err) {
      console.error("error", err.errors[0].longMessage);
      console.error(JSON.stringify(err, null, 2));
      // Check for specific error messages
      if (err.errors && err.errors.length > 0) {
        const errorMessage = err.errors[0].longMessage;
        if (errorMessage.includes("Password is incorrect. Try again, or use another method.")) {
          setModalMessage("Password is incorrect! Try again.");
        } else {
          setModalMessage(errorMessage);
        }
      } else {
        // If no specific message, display a default one
        setModalMessage("Login-up failed. Please try again.");
      }
      // Show error modal
      setShowModal(true);
    }
    setLoading(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        backgroundImage: "url('/authBackground.webp')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: '1rem 0'
      }}
    >
      <div
        style={{
          width: "317px",
          padding: "20px",
          alignItems: "left",
          marginBottom: "40px",
        }}
      >
        <h2
          style={{
            fontFamily: "Epilogue, sans-serif",
            fontWeight: "Bold",
            marginBottom: "2px",
            fontSize: "64px",
            letterSpacing: "1px",
          }}
        >
          Hi!
          <br />
          Welcome
        </h2>
        <p style={{ fontFamily: 'Epilogue, sans-serif', fontStyle: 'italic', color: '#000' }}>"To speak a true word is to transform the world."<span style={{ fontStyle: 'normal', marginLeft: '20px' }}> - By Paulo Freire</span></p>
      </div>

      <form
        style={{
          fontFamily: "Epilogue, sans-serif",
          width: "317px",
          padding: "20px",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <input
            onChange={(e) => setEmailAddress(e.target.value)}
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            style={{
              width: "100%",
              padding: "10px",
              boxSizing: "border-box",
              border: "1px solid #BDBDBD",
            }}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <input
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            style={{
              width: "100%",
              padding: "10px",
              boxSizing: "border-box",
              border: "1px solid #BDBDBD",
            }}
          />
        </div>
        <button
          onClick={handleSubmit}
          disabled={loading}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#0000FF",
            color: "white",
            border: "none",
            cursor: "pointer",
            justifyContent: 'center',
          }}
        >
          {loading ? "Signing in..." : "Sign In"}
        </button>
      </form>

      <div style={{ marginBottom: "10px", textAlign: "right" }}>
        <Link to="/auth/forgot-password" style={{ color: "#4285F4", textDecoration: "underline", cursor: "pointer" }}>
          Forgot Password?
        </Link>
      </div>

      <div style={{ marginTop: "60px", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <p style={{ margin: "0 10px 0 0", color: '#000' }}>Don't have an account?</p>
        <Link to="/auth/signup" style={{ color: "#4285F4", textDecoration: "underline", cursor: "pointer" }}>
          Sign up!
        </Link>
      </div>
      {/* Modal */}
      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '400px',
              textAlign: 'center',
              color: '#000',
            }}
          >
            <p style={{ color: '#000' }}>{modalMessage}</p>
            <button onClick={() => closeModal()}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}
