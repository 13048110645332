import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import './DashboardHome.css'
import ScrollButtonsContainer from '../../components/ScrollButtonsContainer';
import { RightOutlined } from '@ant-design/icons';
import LoadingSpin from '../../components/LoadingSpin';
import DocumentCard from './DocumentCard';
import { Alert, Modal, Typography, Button } from 'antd';
import PdfCard from './PdfCard';

const DashboardHome = () => {
  const { user } = useUser();
  const [textDocuments, setTextDocuments] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [fetchingPdfs, setFetchingPdfs] = useState(false);
  const [fetchingTextDocuments, setFetchingTextDocuments] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [responseAlert, setResponseAlert] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [retry, setRetry] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch Text Documents
    setFetchingTextDocuments(true);
    axios.get(`${apiBaseUrl}/text-documents`, {
      params: {
        userId: user.id
      }
    })
      .then(response => {
        // setTextDocuments(response.data.slice(0, 5));
        setTextDocuments(response.data);
      })
      .catch(error => {
        console.error('Error fetching text documents:', error);
        setError('Error loading documents')
      })
      .finally(() => {
        setFetchingTextDocuments(false);
      });

    // Fetch pdfs
    setFetchingPdfs(true);
    axios.get(`${apiBaseUrl}/pdfs`, {
      params: {
        userId: user.id
      }
    })
      .then(response => {
        // setPdfs(response.data.slice(0, 5));
        setPdfs(response.data);
      })
      .catch(error => {
        console.error('Error fetching PDFs:', error);
        setError('Error loading PDfs')
      })
      .finally(() => {
        setFetchingPdfs(false);
      });
  }, [user, apiBaseUrl]);


  const handlePrev = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + 1, pdfs.length - 1));
  };

  const handleDelete = async (deleteItem) => {
    const { itemId, itemType } = deleteItem;

    let deleteUrl;

    if (itemType === 'pdf') {
      deleteUrl = `${apiBaseUrl}/delete-pdf/${itemId}`;
    } else if (itemType === 'document') {
      deleteUrl = `${apiBaseUrl}/delete-document/${itemId}`;
    } else {
      console.error('Invalid item type:', itemType);
      return;
    }

    try {
      await axios.delete(deleteUrl, {
        params: {
          userId: user.id
        }
      });

      if (itemType === 'pdf') {
        setPdfs(pdfs.filter(item => item.documentId !== itemId));
      } else if (itemType === 'document') {
        setTextDocuments(textDocuments.filter(item => item.documentId !== itemId));
      }

      setResponseAlert(
        <Alert
          style={{ zIndex: 2, position: 'sticky', top: '10px', maxWidth: '400px', justifyContent: 'center', margin: 'auto' }}
          message="Successfully deleted!"
          type="success"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
      console.log("Deleted item with ID:", itemId);
    } catch (error) {
      console.error('Error deleting item:', error);
      setResponseAlert(
        <Alert
          style={{ zIndex: 2, position: 'sticky', top: '10px', maxWidth: '400px', justifyContent: 'center', margin: 'auto' }}
          message="Error deleting item!"
          type="error"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
    } finally {
      setDeleteItem(null);
    }
  };

  const handlePdfDeleteButtonClick = async (documentId) => {
    await setDeleteItem({ itemId: documentId, itemType: 'pdf' });
    setIsDeleteModalVisible(true);
  };

  const handleDocumentDeleteButtonClick = async (documentId) => {
    await setDeleteItem({ itemId: documentId, itemType: 'document' });
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    await handleDelete(deleteItem);
    setIsDeleteModalVisible(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleRetry = () => {
    setError(null);
    setRetry(retry + 1);
  };

  if (error) {
    return (
      <div
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Typography.Text type="danger">{error}</Typography.Text>
        <Button type="primary" onClick={handleRetry} style={{ marginLeft: 8 }}>
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="dashboard">
      {responseAlert}
      {/*  */}
      {(fetchingTextDocuments || fetchingPdfs) ? (
        <LoadingSpin />
      ) : (
        (textDocuments.length === 0 && pdfs.length === 0) && (
          <div className='dashboard-header'>
            <h1 className="dashboard-heading">
              <span>Hello</span>, <span>{user.firstName}</span>
              <br />
              <span>Welcome to Hlina</span>
            </h1>
            <p style={{ color: '#000', fontFamily: 'Epilogue' }}>
              Start exploring and working with your documents in our Study Space.
            </p>
            <div className="dashboard-header-button">
              <Link
                to="/dashboard/study-space"
                style={{
                  color: '#1890ff',
                  textDecoration: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  backgroundColor: 'transparent',
                  border: '1px solid #1890ff',
                  cursor: 'pointer',
                  fontFamily: 'Epilogue',
                }}
              >
                Go to Study Space
                <RightOutlined style={{ marginLeft: '5px' }} />
              </Link>
            </div>
          </div>
        )
      )}

      <div className='pdfs-docs-container'      >
        {textDocuments.length > 0 && (
          <div className="documents-column">
            <div style={{ marginLeft: 0 }}>
              <h2 style={{ fontFamily: 'Epilogue' }}>Your Documents</h2>
            </div>
            <div className="document-container" id='documentContainer'
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                scrollBehavior: 'smooth',
                WebkitOverflowScrolling: 'touch',
                border: '1px solid #ccc',
                borderRadius: '10px',
                padding: '1rem 0'
              }}>
              {textDocuments.map(doc => (
                <DocumentCard
                  key={doc.documentId}
                  document={doc}
                  onDeleteDocument={handleDocumentDeleteButtonClick}
                />
              ))}
            </div>
          </div>
        )}
        {pdfs.length > 0 && (
          <div className="pdfs-column">
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginLeft: '0px' }}>
              <div>
                <h2 style={{ fontFamily: 'Epilogue' }}>Continue Studying</h2>
              </div>
              <div>
                <ScrollButtonsContainer
                  onScrollLeft={() => handlePrev()}
                  onScrollRight={() => handleNext()}
                  disabledLeft={currentIndex === 0}
                  disabledRight={currentIndex === pdfs.length - 1}
                />
              </div>
            </div>
            <div className='pdf-list-warpper'>
              <div className="pdf-list" style={{ display: 'flex', flexDirection: 'row', padding: '0 10px', transition: 'transform 0.5s ease', transform: `translateX(-${currentIndex * 100}%)` }}>
                {pdfs.map(pdf => (
                  <PdfCard key={pdf.documentId} pdf={pdf} onDeletePdf={handlePdfDeleteButtonClick} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleCancelDelete}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: '#F32013', color: '#fff' } }}
      >
        <p style={{ color: '#000' }}>Are you sure you want to delete this item?</p>
      </Modal>
    </div>
  );
};

export default DashboardHome;

