// import React from 'react';
// import { FaSearch } from 'react-icons/fa';

// const SearchBar = () => {
//   const searchBarStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     width: '100%',
//     height: '40px',
//     borderRadius: '60px',
//     overflow: 'hidden',
//     backgroundColor: '#fff',
//     padding: '0 6px'
//   };

//   const searchIconContainerStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: '#D2D7F4',
//     borderRadius: '50%',
//     width: '30px',
//     height: '30px',
//   };

//   const searchIconStyle = {
//     color: '#000',
//   };

//   const searchInputStyle = {
//     flex: 1,
//     height: '100%',
//     padding: '0 16px',
//     border: 'none',
//     outline: 'none',
//     fontSize: '14px',
//     background: 'transparent',
//     fontFamily: "Inter, sans-serif",
//   };

//   return (
//     <div style={searchBarStyle}>
//       <div style={searchIconContainerStyle}>
//         <FaSearch style={searchIconStyle} />
//       </div>
//       <input
//         type="text"
//         placeholder="Search anything"
//         style={searchInputStyle}
//       />
//     </div>
//   );
// };

// export default SearchBar;


import React, { useState, useRef, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { Empty } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './SearchBar.css'
import { RightOutlined } from '@ant-design/icons';

const SearchBar = () => {
  const { user } = useUser()
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setSearchTerm('');
    setSearchResults([]);
    setLoading(false);
    setIsFocused(false);
  }, [location.pathname]);



  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsFocused(false);
    }
  };

  const searchResultContainerStyle = {
    display: isFocused ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'absolute',
    top: '56px',
    left: 0,
    zIndex: 9999,
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '1rem',
    maxHeight: '400px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
  };

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    borderRadius: '60px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    padding: '0 0 0 6px',
    position: 'relative'
  };

  const searchIconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#D2D7F4',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
  };

  const searchIconStyle = {
    color: '#000',
  };

  const searchInputStyle = {
    flex: 1,
    height: '100%',
    padding: '0 16px',
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    background: 'transparent',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const text = {
    fontFamily: 'Epilogue',
    fontSize: '24px',
    marginBottom: '10px',
    color: '#808080'
  }

  // const fetchSearchResults = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${apiBaseUrl}/search?query=${searchTerm}&user_id=${user.id}`);
  //     const data = await response.json();
  //     setSearchResults(data);
  //   } catch (error) {
  //     console.error('Error fetching search results:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const fetchSearchResults = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/search`, {
        params: {
          query: searchTerm,
          user_id: user.id
        }
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  // const handleInputChange = (event) => {
  //   setSearchTerm(event.target.value);
  //   fetchSearchResults();
  // };

  let debounceTimeout;
  const handleInputChange = (event) => {
    const inputTerm = event.target.value;
    setSearchTerm(inputTerm);

    // Clear the previous timeout
    clearTimeout(debounceTimeout);

    // Set a new timeout
    debounceTimeout = setTimeout(() => {
      fetchSearchResults(inputTerm);
    }, 300);
  };

  // const handleInputChange = (event) => {
  //   setSearchTerm(event.target.value);
  //   if (event.target.value === '') {
  //     setSearchResults([]);
  //   } else {
  //     fetchSearchResults();
  //   }
  // };

  return (
    <div className='search-bar-wrapper' ref={dropdownRef}>
      <div style={searchBarStyle}>
        <span style={searchIconContainerStyle}>
          <FaSearch style={searchIconStyle} />
        </span>
        <input
          type="text"
          placeholder="Search any documents by title or content"
          style={searchInputStyle}
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
        />
      </div>

      <div className='search-result-container' style={searchResultContainerStyle}>
        {loading &&
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div style={text}>
                  Loading...
                </div>
              }
            /></div>}
        {!loading && searchResults.length === 0 &&
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div style={text}>
                  No results found
                </div>
              }
            />
          </div>
        }
        {/*  */}
        {!loading && searchResults.length > 0 && (
          <div style={{}}>
            {searchResults.map((result, index) => (
              <div key={index}>
                {result.source === 'text_editor_collection' && (
                  <div className="text-docs-result-container">
                    <ul>
                      <li>
                        <Link to={`/dashboard/study-space/text-editor/${result.documentId}`} className="result-link">
                          <span className="result-link-text">{result.name}</span>
                          <span className="result-link-icon"><RightOutlined /></span>
                        </Link>

                      </li>
                    </ul>
                  </div>
                )}

                {result.source === 'pdf_collections' && (
                  <div className="pdfs-result-container">
                    <ul>
                      <li>
                        <Link to={`/dashboard/study-space/pdf-dialogue/${result.documentId}`} className="result-link">
                          <span className="result-link-text">{result.fileName}</span>
                          <span className="result-link-icon"><RightOutlined /></span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;