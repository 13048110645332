import React, { useEffect, useRef, useState } from 'react';
import { FaPaperPlane, FaRegCopy } from 'react-icons/fa';
import { KeyTakeawaysSVG, ExplainSvg, SummarySvg, ChatQuestionSVG, SaveSVG } from './svgs/ButtonIconsSvgs';
import LoadingIndicator from './LoadingIndicator';
import { Input } from 'antd';
const { TextArea } = Input;

const WorkRoomChatInterface = ({
    copyResponse,
    handleQuickButtonClick,
    handleFollowUpClick,
    user,
    errorAlert,
    responseAlert,
    saveKeyPoints,
    pdfData,
    isResponding,
    selectedText,
    getKeyPoints,
    getSummary,
    getExplanation,
    conversations,
    retrievedMessage,
    setRetrievedMessage,
    handleRetrieve
}) => {
    const [showFullSelectedText, setShowFullSelectedText] = useState(false);
    const chatRef = useRef(null);
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const currentDate = new Date();
        const currentHour = currentDate.getHours();

        let newGreeting;
        if (currentHour >= 5 && currentHour < 12) {
            newGreeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 18) {
            newGreeting = 'Good afternoon';
        } else {
            newGreeting = 'Good evening';
        }

        setGreeting(newGreeting);
    }, []);

    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversations, selectedText, isResponding]);

    const getBackgroundColor = (type) => {
        switch (type) {
            case 'KeyPoints':
                return '#f0f0f0';
            case 'summary':
                return '#c0c0c0';
            case 'explanation':
                return '#d0d0d0';
            case 'retrievalChat':
                return 'transparent';
            default:
                return '#e0e0e0';
        }
    }

    const toggleText = () => {
        setShowFullSelectedText(!showFullSelectedText);
    };

    return (
        <div
            className='work-room-intfc-chat-container' ref={chatRef}
        >
            <div className='header' style={{ width: '80%', margin: 'auto' }}>
                <h2 style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1.7rem", fontWeight: "900", textAlign: 'center' }}>{`${greeting}, ${user.firstName}`}</h2>
                <p style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1rem", fontWeight: "400", textAlign: 'center', color: '#000' }}>
                    Sabrina, our most advanced AI, got your back today.
                </p>

                <p style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1rem", fontWeight: "400", textAlign: 'center', color: '#000' }}>
                    I can explain, summarize, and put together Key Points for you. Just copy any text from the PDF to see what I can do for you.
                </p>

            </div>

            {/*...*/}
            {errorAlert}
            {responseAlert}
            {/*...*/}

            {selectedText &&
                <div style={{
                    width: '80%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '0.5rem',
                    padding: '0.5rem',
                    margin: 'auto'
                }}>
                    {selectedText.length > 200 &&
                        <button onClick={() => handleQuickButtonClick(getKeyPoints)} style={{ width: '120px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', padding: '1rem', borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px' }}><KeyTakeawaysSVG /><span style={{ fontFamily: 'Epilogue', fontSize: '11px', textAlign: 'left' }}>key Takeaways</span></button>
                    }
                    {selectedText.length > 200 &&
                        <button onClick={() => handleQuickButtonClick(getSummary)} style={{ width: '120px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', padding: '1rem', borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px' }}><SummarySvg /><span style={{ fontFamily: 'Epilogue', fontSize: '11px', textAlign: 'left' }}>Summary</span></button>
                    }
                    <button onClick={() => handleQuickButtonClick(getExplanation)} style={{ width: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', padding: '1rem', borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px' }}><ExplainSvg /> <span style={{ fontFamily: 'Epilogue', fontSize: '11px', textAlign: 'left' }}>Explain</span></button>
                </div>
            }

            <div>
                {conversations.map((conv, convIndex) => (
                    <div key={convIndex}>
                        <div
                            style={{
                                margin: '1rem',
                                padding: '1rem',
                                borderRadius: '4px',
                                marginBottom: '0.5rem',
                                backgroundColor: getBackgroundColor(conv.type),
                                boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                            }}
                        >
                            {/* KeyPoints conversation types*/}
                            {conv.type === 'KeyPoints' &&
                                conv.messages.map((msg, msgIndex) => (
                                    <div
                                        key={msgIndex}
                                        style={{
                                            margin: '1rem',
                                        }}
                                    >
                                        {msg.sender.type === 'User' && msg.type === 'KeyPoints' && (
                                            <p style={{ fontStyle: 'italic', fontFamily: 'Epilogue', color: '#000' }}>
                                                <strong>{msg.sender.name.UserName}:</strong> Generate key points
                                                <br />
                                                {showFullSelectedText ? msg.text : msg.text.substring(0, 100)}
                                                {msg.text.length > 100 &&
                                                    <span
                                                        className="toggle-link"
                                                        onClick={toggleText}
                                                        style={{ cursor: 'pointer', color: '#808080', marginLeft: '5px', fontFamily: 'Epilogue' }}
                                                    >
                                                        {showFullSelectedText ? 'See Less' : '...'}
                                                    </span>
                                                }
                                            </p>
                                        )}
                                        {msg.sender.type === 'AI' && msg.type === 'KeyPoints' && (
                                            <div>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                    <strong>{msg.sender.name.AIName}:</strong>
                                                </p>
                                                <ul style={{ listStyleType: 'none' }}>
                                                    {msg.text.split('\n').map((point, index) => (
                                                        <li key={index}>{point}</li>
                                                    ))}
                                                </ul>

                                                <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                                    <button onClick={() => saveKeyPoints(msg.text, pdfData.fileName)} style={{ justifyContent: 'center', padding: '0.5rem' }}><SaveSVG color="#808080" /></button>
                                                    <button onClick={() => copyResponse(msg.text)} style={{ justifyContent: 'center', padding: '0.5rem' }}><FaRegCopy style={{ color: '#808080', fontSize: '18px' }} /></button>
                                                </div>
                                            </div>
                                        )}
                                    </div>


                                ))}
                            {/* summary conversation types */}
                            {conv.type === 'summary' &&
                                conv.messages.map((msg, msgIndex) => (
                                    <div
                                        key={msgIndex}
                                        style={{
                                            margin: '1rem',
                                        }}
                                    >
                                        {msg.sender.type === 'User' && msg.type === 'summary' && (
                                            <p style={{ fontStyle: 'italic', fontFamily: 'Epilogue', color: '#000' }}>
                                                <strong>{msg.sender.name.UserName}:</strong> Summarize
                                                <br />
                                                {showFullSelectedText ? msg.text : msg.text.substring(0, 100)}
                                                {msg.text.length > 100 &&
                                                    <span
                                                        className="toggle-link"
                                                        onClick={toggleText}
                                                        style={{ cursor: 'pointer', color: '#808080', marginLeft: '5px', fontFamily: 'Epilogue' }}
                                                    >
                                                        {showFullSelectedText ? 'See Less' : '...'}
                                                    </span>
                                                }
                                            </p>
                                        )}
                                        {msg.sender.type === 'AI' && msg.type === 'summary' && (
                                            <div>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                    <strong>{msg.sender.name.AIName}:</strong>
                                                </p>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>{msg.text}</p>

                                                <div style={{}}>
                                                    <button onClick={() => copyResponse(msg.text)} style={{ justifyContent: 'center', padding: '0.5rem', background: 'transparent' }}><FaRegCopy style={{ color: '#808080', fontSize: '18px' }} /></button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            {/* explanation conversation types */}
                            {conv.type === 'explanation' &&
                                conv.messages.map((msg, msgIndex) => (
                                    <div
                                        key={msgIndex}
                                        style={{
                                            margin: '1rem',
                                        }}
                                    >
                                        {msg.sender.type === 'User' && msg.type === 'explanation' && (
                                            <p style={{ fontStyle: 'italic', fontFamily: 'Epilogue', color: '#000' }}>
                                                <strong>{msg.sender.name.UserName}:</strong> Explain
                                                <br />
                                                {showFullSelectedText ? msg.text : msg.text.substring(0, 100)}
                                                {msg.text.length > 100 &&
                                                    <span
                                                        className="toggle-link"
                                                        onClick={toggleText}
                                                        style={{ cursor: 'pointer', color: '#808080', marginLeft: '5px', fontFamily: 'Epilogue' }}
                                                    >
                                                        {showFullSelectedText ? 'See Less' : '...'}
                                                    </span>
                                                }
                                            </p>
                                        )}
                                        {msg.sender.type === 'AI' && msg.type === 'explanation' && (
                                            <div>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                    <strong>{msg.sender.name.AIName}:</strong>
                                                </p>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>{msg.text}</p>

                                                <div style={{}}>
                                                    <button onClick={() => copyResponse(msg.text)} style={{ justifyContent: 'center', padding: '0.5rem', background: 'transparent' }}><FaRegCopy style={{ color: '#808080', fontSize: '18px' }} /></button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            {/* retrievalChat conversation types */}
                            {conv.type === 'retrievalChat' &&
                                conv.messages.map((msg, msgIndex) => (
                                    <div
                                        key={msgIndex}
                                        style={{
                                            margin: '1rem',
                                        }}
                                    >
                                        {msg.sender.type === 'User' && msg.type === 'retrievalChat' && (
                                            <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                <strong>{msg.sender.name.UserName}:</strong>
                                                <br />
                                                {msg.text}
                                            </p>
                                        )}

                                        {msg.sender.type === 'AI' && msg.type === 'retrievalChat' && (
                                            <div>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                    <strong>{msg.sender.name.AIName}:</strong>
                                                </p>
                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                    {msg.text
                                                        .split("Follow-up Questions:")[0]
                                                        .split("Answer:")
                                                    }
                                                </p>
                                                {/* Add your follow-up questions as buttons */}
                                                {msg.text
                                                    .split("Follow-up Questions:")[1]
                                                    .split(/\d+\.\s/)
                                                    .filter((q) => q.trim())
                                                    .map((question, index) => (
                                                        <button
                                                            style={{ display: 'flex', justifyContent: 'center', gap: '4px', marginBottom: '10px', padding: '1rem', borderRadius: '4px', borderStyle: 'solid', borderWidth: '1px' }}
                                                            key={index}
                                                            onClick={() => handleFollowUpClick(question.trim())}>
                                                            <div style={{ width: '24px', height: '24px' }}>
                                                                <ChatQuestionSVG />
                                                            </div>
                                                            <span>
                                                                <p style={{ fontFamily: 'Epilogue', color: '#000' }}>
                                                                    {question.trim()}
                                                                </p>
                                                            </span>
                                                        </button>

                                                    ))}
                                            </div>
                                        )}

                                    </div>
                                ))}
                        </div>
                    </div>
                ))}

                <LoadingIndicator style={{ width: '100%', margin: '1rem' }} isLoading={isResponding} />

            </div>

            <div
                style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'sticky', zIndex: 1, bottom: '0px'
                }}
            >
                <div className='textarea-warpper'
                    style={{
                        margin: '1rem',
                        position: 'sticky',
                        zIndex: 1,
                        bottom: '10px',
                        width: '90%',
                        background: '#fff',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}>
                    <TextArea
                        value={retrievedMessage}
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        placeholder='Ask anything from the document...'
                        // onChange={(e) => setRetrievedMessage(e.target.value)}
                        onChange={(e) => setRetrievedMessage(e.target.value.slice(0, 2000))}
                        disabled={isResponding}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleRetrieve(e);
                            }
                        }}
                        style={{
                            padding: '10px',
                            background: 'trasparent',
                            borderRadius: '0px',
                            overflowY: 'auto',
                            border: 'none',
                            resize: 'none',
                            outline: 'none',
                            MsOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                            WebkitScrollbar: {
                                display: 'none'
                            }
                        }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-end', padding: '0.5rem' }}>
                        <span style={{ color: '#666', fontSize: '0.8rem' }}>{retrievedMessage.length}/2000</span>
                        <button
                            style={{
                                borderRadius: '4px',
                                padding: '10px',
                                cursor: 'pointer',
                                border: 'none',
                                justifyContent: 'center'
                            }}
                            onClick={handleRetrieve}
                            disabled={!retrievedMessage.trim() || isResponding}
                        >
                            <FaPaperPlane style={{ fontSize: '18px', verticalAlign: 'middle' }} />
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default WorkRoomChatInterface;
