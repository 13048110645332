import React, { useState, useEffect } from 'react';
import { useSignIn, useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [complete, setComplete] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const { isLoaded, signIn, setActive } = useSignIn();
  const navigate = useNavigate();
  const { isSignedIn } = useUser();

  useEffect(() => {
    if (isSignedIn) {
      navigate("/dashboard");
    }
  }, [isSignedIn, navigate]);

  if (!isLoaded) {
    return null;
  }

  async function create(e) {
    e.preventDefault();
    await signIn
      ?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      .then(_ => {
        setSuccessfulCreation(true);
        setShowModal(true);
        setModalMessage('Password reset email sent successfully.');
      })
      .catch(err => {
        setShowModal(true);
        setModalMessage(`${err.errors[0].longMessage}`);
      });
  }

  async function reset(e) {
    e.preventDefault();
    await signIn
      ?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      })
      .then(result => {
        if (result.status === 'needs_second_factor') {
          setSecondFactor(true);
        } else if (result.status === 'complete') {
          setActive({ session: result.createdSessionId });
          setComplete(true);
          setShowModal(true);
          setModalMessage('Password reset successful. Redirecting to the dashboard.');
          navigate('/dashboard');
        } else {
          console.log(result);
        }
      })
      .catch(err => {
        setShowModal(true);
        setModalMessage(`${err.errors[0].longMessage}`);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        backgroundImage: "url('/authBackground.webp')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: '1rem 0'
      }}
    >
      <div
        style={{
          width: "317px",
          padding: "20px",
          alignItems: "left",
          marginBottom: "40px",
        }}
      >
        <h2
          style={{
            fontFamily: "Inter, sans-serif",
            fontWeight: "Bold",
            marginBottom: "8px",
            fontSize: "64px",
            letterSpacing: "1px",
            textAlign: "left",
          }}
        >
          Oh, no!
          <br />
          I forgot
        </h2>
      </div>

      <form
        style={{
          fontFamily: "Inter, sans-serif",
          width: "317px",
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={!successfulCreation ? create : reset}
      >
        {!successfulCreation && !complete && (
          <>
            <div style={{ marginBottom: "20px" }}>
              <input
                type='email'
                placeholder='Enter Your Email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  boxSizing: 'border-box',
                  border: "1px solid #BDBDBD",
                }}
              />
            </div>

            <div style={{ marginTop: "30px" }}>

              <button
                style={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: '#0000FF',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  justifyContent: 'center',
                }}
              >
                Send Reset Code
              </button>
            </div>
          </>
        )}

        {successfulCreation && !complete && (
          <>
            <div style={{ marginBottom: "20px" }}>
              <input
                type='password'
                value={password}
                placeholder='New Password'
                onChange={e => setPassword(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  boxSizing: 'border-box',
                  border: "1px solid #BDBDBD",
                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <input
                type='text'
                value={code}
                placeholder='Reset Password code'
                onChange={e => setCode(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  boxSizing: 'border-box',
                  border: "1px solid #BDBDBD",
                }}
              />
            </div>

            <div style={{ marginTop: "30px" }}>
              <button
                style={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: '#0000FF',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  justifyContent: 'center',
                }}
              >
                Reset
              </button>
            </div>
          </>
        )}

        {complete && (
          <p style={{ textAlign: 'center', marginTop: '10px', color: '#000' }}>
            You successfully changed your password
          </p>
        )}
        {secondFactor && (
          <p style={{ textAlign: 'center', marginTop: '10px', color: '#000' }}>
            2FA is required, this UI does not handle that
          </p>
        )}
      </form>


      {!successfulCreation && !complete && (
        <div style={{ marginTop: "60px", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p style={{ margin: "0 10px 0 0", cursor: "pointer", color: '#000' }} onClick={() => { navigate("/auth/login"); }}>
            &#8592; Go back
          </p>
        </div>
      )}

      {successfulCreation && !complete && (
        <div style={{ marginTop: "60px", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p style={{ margin: "0 10px 0 0", cursor: "pointer", color: '#000' }} onClick={() => { navigate("/auth/login"); }}>
            &#8592; Go back
          </p>
        </div>
      )}


      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: 'white',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '400px',
              textAlign: 'center',
            }}
          >
            <p style={{ color: '#000' }}>{modalMessage}</p>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
