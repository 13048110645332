import React from 'react';
import ScrollButton from './ScrollButton';

const ScrollButtonsContainer = ({ onScrollLeft, onScrollRight, disabledLeft, disabledRight }) => {
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <ScrollButton direction="left" onClick={onScrollLeft} disabled={disabledLeft} />
      <ScrollButton direction="right" onClick={onScrollRight} disabled={disabledRight} />
    </div>
  );
};

export default ScrollButtonsContainer;
