import React from 'react';
import { Spin } from 'antd';

const LoadingSpin = () => {
    const style = {
        loadingSpinner: {
            zIndex: 9,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    };
    return (
        <Spin size="large" style={style.loadingSpinner} />
    );
};

export default LoadingSpin;
