import React, { useState, useEffect } from 'react';
import { Outlet, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import ResourceCenter from './ResourceCenter';
import UserProfile from './UserProfile';
import Settings from './Settings';
import { useClerk } from '@clerk/clerk-react';
import Sidebar from 'react-sidebar';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { LogoutOutlined } from '@ant-design/icons';
import DashboardHome from './DashboardHome';
import SearchBar from '../../components/SearchBar';
import { Tooltip } from 'antd';
import { ResourceLibrarySVG, StudyRoomSVG, UserProfileSVG, SettingsSVG, DashboardIconSVG } from '../../components/svgs/ButtonIconsSvgs'
import StudySpaceRoutes from '../studySpace/StudySpaceRoutes';
import UserButton from '../../components/UserButton';

export function Dashboard() {
  const { signOut } = useClerk();
  const navigate = useNavigate();
  // const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  // useEffect(() => {
  //   if (window.innerWidth < 767) {
  //     setSidebarOpen(false);
  //   }
  // }, []);

  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 767);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 767);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 767);
      if (screenWidth < 767) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    handleResize(); // Call once to set initial state
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const CustomTooltip = ({ title, ...props }) => (
    <Tooltip
      {...props}
      title={<span style={{ color: '#fff', fontSize: '12px', fontFamily: 'Epilogue', fontWeight: 'bold' }}>{title}</span>}
    />
  );

  const handleLogout = () => {
    signOut(() => navigate("/"));
  };

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');

    if (window.innerWidth < 767) {
      setSidebarOpen(false);
    }
  };

  const sidebarContent = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ background: '#0000FF', padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', height: '40px', width: '100%' }}>
          <h2 style={{ color: '#FFF', fontFamily: 'Epilogue', margin: '0' }}>HLINA</h2>
          <span style={{ verticalAlign: 'midle', backgroundColor: '#FFA500', color: '#FFF', padding: '2px 6px', borderRadius: '4px', fontSize: '12px', marginLeft: '6px' }}>BETA</span>
        </div>
      </div>
      <div style={{ flex: 1, overflowY: 'auto', backgroundColor: '#0000FF' }}>
        <ul style={{ listStyle: 'none', padding: 0, margin: '2rem 0 0 0' }}>
          <li style={{ padding: '0.5rem 1rem', marginBottom: '0.5rem', background: location.pathname === '/dashboard' ? '#647AF2' : 'none' }}>
            <Link to="/dashboard" onClick={handleNavigateToDashboard} style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <DashboardIconSVG style={{ marginRight: '10px' }} />
              <span style={{ fontFamily: "Epilogue, sans-serif", fontSize: "0.8rem", fontWeight: "600", }}>Dashboard</span>
            </Link>
          </li>

          <li style={{ padding: '0.5rem 1rem', marginBottom: '0.5rem', background: location.pathname.startsWith('/dashboard/study-space') ? '#647AF2' : 'none' }}>
            <Link to="study-space" style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <StudyRoomSVG style={{ marginRight: '10px' }} />
              <span style={{ fontFamily: "Epilogue, sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>Study Space</span>
            </Link>
          </li>

          <li style={{ padding: '0.5rem 1rem', marginBottom: '0.5rem', background: location.pathname.startsWith('/dashboard/resource-center') ? '#647AF2' : 'none' }}>
            <Link to="resource-center" style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <ResourceLibrarySVG style={{ marginRight: '10px' }} />
              <span style={{ fontFamily: "Epilogue, sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>
                Resource Enclave
              </span>
            </Link>
          </li>

          <li style={{ padding: '0.5rem 1rem', marginBottom: '0.5rem', background: location.pathname === '/dashboard/profile' ? '#647AF2' : 'none' }}>
            <Link to="profile" style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <UserProfileSVG style={{ marginRight: '10px' }} />
              <span style={{ fontFamily: "Epilogue, sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>Acount Zone</span>
            </Link>
          </li>

          <li style={{ padding: '0.5rem 1rem', marginBottom: '0.5rem', background: location.pathname === '/dashboard/settings' ? '#647AF2' : 'none' }}>
            <Link to="#" style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              < SettingsSVG style={{ marginRight: '10px' }} />
              <span style={{ fontFamily: "Epilogue, sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>Control Room</span>
            </Link>
          </li>
        </ul>
      </div>

      <div style={{ padding: '1rem', background: '#0000FF' }}>
        <hr style={{ width: '100%', border: 'none', height: '1px', backgroundColor: '#fff' }} />
        <button onClick={handleLogout} style={{ background: 'transparent', color: '#FFF', border: 'none', padding: '0.5rem 1rem', cursor: 'pointer', borderRadius: '4px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <LogoutOutlined style={{ marginRight: '10px', fontSize: '24px' }} />
          <span style={{ fontFamily: "Epilogue, sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>Sign Out</span>
        </button>
      </div>
    </div>
  );

  return (
    <Sidebar
      sidebar={sidebarContent}
      open={sidebarOpen}
      onSetOpen={(open) => setSidebarOpen(open)}
      styles={{
        sidebar: { width: '260px', zIndex: 9999, overflowY: 'auto' },
        content: { overflowY: 'auto', marginLeft: sidebarOpen && !isMobile ? '260px' : '0', transition: 'margin-left 0.3s' },
        overlay: { display: isMobile ? '' : 'none', zIndex: 999, },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', minWidth: "100%" }}>
        <div style={{ width: 'calc(100% - 2rem)', background: '#F0F0F0', padding: '1rem', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
            <div>
              <CustomTooltip
                title={sidebarOpen ? "Close sidebar" : "Open sidebar"}
                placement="right"
              >
                <button onClick={() => setSidebarOpen(!sidebarOpen)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "40px", width: "40px", background: '#fff', borderRadius: '8px', border: 'none', cursor: 'pointer' }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {sidebarOpen ? <FaAngleLeft style={{ fontSize: '18px' }} /> : <FaAngleRight style={{ fontSize: '18px' }} />}
                  </span>
                </button>
              </CustomTooltip>
            </div>
            <SearchBar />
          </div>
          <div>
            <UserButton />
          </div>
        </div>
        <div style={{ flex: 1, padding: '1rem', backgroundColor: '#F0F0F0', overflowY: 'auto' }}>
          <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="study-space/*" element={<StudySpaceRoutes />} />
            <Route path="resource-center" element={<ResourceCenter />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
          <Outlet />
        </div>
      </div>
    </Sidebar>
  );
}
