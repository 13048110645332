import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import './HomePageStyles.css';
import NavBar from './NavBar';
import Footer from './Footer';


function Home() {
  const slideContainerRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollLeft = slideContainerRef.current.scrollLeft;
      const cardWidth = slideContainerRef.current.children[0].offsetWidth + 20;
      const index = Math.round(scrollLeft / cardWidth);
      setCurrentSlide(index);
    };

    const containerRef = slideContainerRef.current;
    containerRef.addEventListener('scroll', handleScroll);

    return () => {
      containerRef.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToCard = (index) => {
    const cardWidth = slideContainerRef.current.children[0].offsetWidth + 20;
    slideContainerRef.current.scrollTo({
      left: index * cardWidth,
      behavior: 'smooth',
    });
    setCurrentSlide(index);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isBlogOpen, setIsBlogOpen] = useState(false);


  const toggleReadMore = () => {
    setIsOpen(!isOpen);
  };
  const toggleBlogReadMore = () => {
    setIsBlogOpen(!isBlogOpen);
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <NavBar SignedIn={SignedIn} SignedOut={SignedOut} />

      <div className='hero-section'>
        <div className='hero-section-tag-line'>
          <h1>
            Welcome to
            <br />
            <span>Hlina</span>
          </h1>
          <p>Take control of your education now</p>
        </div>
      </div>

      <div id='take-control' className="education-re-imagined-section">
        <div className='education-re-imagined-section-warpper'>
          <div className="left-sec-comp">
            <div className='left-sec-comp-text'>
              <p>
                Imagine an education informed and inspired by what is ahead.
                Imagine yourself not just with the knowledge to meet that future but also with insights and wisdom that power your agency.
                Imagine a world where your existence isn’t a mere coincident rather one that holds profound significance and potent meanings.
                Now re-imagine your education from what has been to what is and will be.
              </p>
            </div>
            <div className='left-sec-comp-links'>
              <Link className="link-login" to="/auth/login">Login</Link>
              {/* <Link className="link-pricing" to="#">Pricing</Link> */}
              <Link className="link-pricing" to="#" onClick={scrollToContact}>Request invitation code</Link>
            </div>
          </div>
          <div className="right-sec-comp">
            <div className='right-sec-comp-title'>
              <h2>Education Re-Imagined</h2>
            </div>
            <div className='right-sec-comp-paragraph'>
              <p>
                For the first time in a really long time, YOU, the learner, will take an unprecedented control over your educational journey with Hlina.
                We start that journey with the one that matter the most, your study and workspace. Hlina will give you extraordinary access to our highly capable workhorse AI, Sabrina.
                And that is not all... our AI buddies will not only humor but also become your most confidant collaborator.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id='who-we-are' className='who-we-are-section'>
        <div className='who-we-are-section-warpper'>
          <div>
            <h2>
              Education informed
              <br />
              by the kind of future
              <br />
              we envision
            </h2>
          </div>

          <div>
            <>
              <p>
                The UNESCO Global Education Monitoring Report revealed that in 2021, over 240
                million students who are school age around the world were missing out on education at
                different levels of the mainstream system, from primary to upper secondary. About one
                hundred million of them were in sub-Saharan regions, a shocking 40% or so of the
                global total. The report also showed that in the 21 years since the first report, the same
                African region has added 20 million more children out of school. (UNESCO, 2022) And
                this is not even counting the cumulative and compounding effects of such dismal
                performance in the education space. The negative impact of such disparity and disarray
                impacts all aspects of socio-economic and public life at the local and global level. It
                affects large segments of our social fabrics in ways we cannot even fully grasp or
                measure yet. Every minute, hour, day, and year a child is out of school only makes the
                impact worse and increases the opportunity cost for everything else. And that is
                assuming that those who manage to complete the total education lifecycle are doing so
                while meeting the learning or pedagogical objectives. So, the challenge is not just
                access or even quality, but the attitude, aptitude, resilience, insight, determination, skill,
                motivation, and values that emerge at the end of such a system, whether it is completed
                or not. Now ask yourself these simple questions: what kind of world would we have if
                humanity exhibited a higher level of consciousness characterized by virtues such as
                insight, wisdom, grit, determination, passion, compassion, meaning and many more that
                we as a community of people have come to value as something common among all of
                us? How would the world look like?
              </p>
            </>
            {isOpen && (
              <>
                <p>
                  <strong>Education Re-Imagined</strong> is an EdTech
                  startup that aims to transform the way we understand, deliver, and experience
                  education in our world. It is an initiative based on a vision of humanity that is uplifted,
                  empowered, and humanized. Here we imagine an education that is democratic and
                  hyper collaborative. An education that guides learners to a higher level of awareness,
                  meaning and purpose. We believe that only when humanity is elevated in such a deep
                  and profound way that it can, not only deal with the challenges of our time, but also
                  seize the opportunities of the future. We envision, in a world that will be hyper
                  dominated by technology, humanity will continue to be central and play the leading role.
                  The education system as we know it is inefficient, expensive, and elitist at best to meet
                  the world that awaits us. It is a system that has and continues to let down millions
                  around the world, and that is not expected to improve moving forward. It epitomizes the
                  numerous broken promises and the flood of unmet potential of many in our world today.
                  As a result, we pay dearly for every potential agency that we have failed to activate and
                  elevate. From climate crisis to failed states, from extreme poverty to unchecked
                  materialism, and from all types of ills to the stagnation in our world today, one can link
                  failure to educate and humanize. At Education Re-Imagined, we are daring to reimagine
                  not only the mechanics of, but also the essence and meaning of, education itself.
                </p>
                <p>
                  We envision an education that is free from the constraints of cost, control, and
                  bureaucracy. We seek to rethink not only the mechanics of teaching, instruction and
                  learning, but also the nature and substance of what constitutes education. With recent
                  advances in technology, we are closer than ever to the holy grail of education, one that
                  is hyper personalized, customized, relevant, timely and contextual. Advances in
                  computing power as well as Artificial Intelligence and Machine Learning capabilities
                  have created new possibilities for achieving education that looks ahead, readying
                  humanity for the future. The ability to deliver on such a promise will significantly lower
                  the barrier while also elevating the nature and substance of learning to a higher order of
                  human qualities. Our knowledge of human cognition and consciousness can now be
                  applied and implemented not to mold and shape, but to elevate, empower, catalyze, and
                  enhance individual and community agencies for the good of all humanity.At the heart of
                  Education Re-Imagined’s approach are the enhancement of tools and means with which
                  a learner can gain greater mobility and flexibility. We bring the best available tools at the
                  disposal of our users to set the pace and quality of learning, giving you a greater sense
                  of agency. Here at this humble beginning, you have chosen to accompany our
                  ambitious journey towards the fulfillment of the promise of our vision, an education that
                  is fit for a 21 st century world and beyond. Our first step is to help you take control of your
                  learning space and work environment.
                </p>
                <p>
                  To this effect, we are super excited to introduce
                  you our first product, Hlina (ህሊና). Hlina AI, an Amharic word for consciousness or deep
                  sense of self-awareness, seeks to bring the power of computing and Artificial
                  Intelligence at the disposal of those who are seeking to elevate their learning game and
                  thrive in their educational journey. It is a product of a conscious attempt at one of the
                  most profound yet often neglected aspects of learning, peer-to-peer, and self-guided
                  education. Hlina AI will afford you with greater autonomy and flexibility in building your
                  workspace powered by capable companions, Sabrina AI who will assist you on some of
                  the grunt work and tasks, as well as Daniella and Robel who will be your peer study
                  buddies to work and collaborate with.
                </p>
              </>
            )}
          </div>

          <div>
            <Link onClick={toggleReadMore}>
              {isOpen ? 'Read Less' : 'Read More'}
            </Link>
          </div>
        </div>
      </div>

      <div id='features' className='features-section'>
        <div className='features-section-wrapper'>
          <div className='features-sec-header'>
            <h2>Hlina In Action</h2>
            <p>Hlina offers a comprehensive suite of tools designed to empower you in taking control of your educational journey.
              Let's begin by exploring the key features:
            </p>
          </div>
          <div className='features-card-container'>
            <div className='features-slide-container' ref={slideContainerRef}>
              <div className='feature-card'>
                <div className='feature-card-image-container'>
                  <img src='./ft-1.png' alt='Study Space' />
                </div>
                <div className='feature-card-desc-container'>
                  <h3>
                    Easily upload PDFs or open a text editor directly from your dashboard for organized study sessions designed to fit your needs.
                  </h3>
                </div>
              </div>
              <div className='feature-card'>
                <div className='feature-card-image-container'>
                  <img src='./ft-2.png' alt='Work With Sabrina' />
                </div>
                <div className='feature-card-desc-container'>
                  <h3>
                    Maximize your study potential with Sabrina AI! Dive deep into your study material with Sabrina's assistance, making learning more efficient and effective.
                  </h3>
                </div>
              </div>
              <div className='feature-card'>
                <div className='feature-card-image-container'>
                  <img src='./ft-3.png' alt='Work With Sabrina' />
                </div>
                <div className='feature-card-desc-container'>
                  <h3>
                    Seamlessly jot down ideas, notes, or drafts with Sabrina's assistance, turning your thoughts into polished work effortlessly.
                  </h3>
                </div>
              </div>
              <div className='feature-card'>
                <div className='feature-card-image-container'>
                  <img src='./ft-4.png' alt='Chat With Daniela' />
                </div>
                <div className='feature-card-desc-container'>
                  <h3>
                    Connect, chat, and get support from virtual study partners who are here to motivate you and help with your academic journey.
                  </h3>
                </div>
              </div>
            </div>
            <div className='features-slide-controls'>
              {[0, 1, 2, 3].map((index) => (
                <button
                  key={index}
                  onClick={() => scrollToCard(index)}
                  className={`control-button ${currentSlide === index ? 'active' : ''}`}
                >
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div id='the-forward' className='the-forward-section'>
        <div className='the-forward-section-warpper'>
          <div>
            <h2>
              Time To Re-imagine And Re-envision Education
              <br />
              <writer style={{ color: '#808080', fontSize: '20px' }}>
                – by Ezra M. Nigussie (CEO of
                Education Re-Imagined)
              </writer>
            </h2>
          </div>

          <div>
            <p>
              My decade-long experience in education has revealed a recurring and troubling theme:
              education often neglects the fundamental &#39;why&#39; and &#39;how&#39; questions. True education
              must be forward-looking, yet too often it remains anchored to fleeting trends and the
              demands of the moment. We need discourse that starts with our vision for the future – a
              future where human expression and community connection shape learning.

              Today&#39;s education should serve a higher purpose than just fulfilling our past and present
              needs. It must be the key to shaping the future. Sadly, it has been reduced to producing
              learners who fit neatly into rigid systems, shedding their own passions and potential in
              exchange for compliance and comportment (you must fit is pretty much the motto of
              such a system). My own educational journey left me feeling disconnected and
              powerless, and I see this same pattern replicated across the globe.
            </p>
            {isBlogOpen && (
              <>
                <p>
                  Education systems often prioritize standardization over celebrating unique human
                  qualities. They prioritize production-ready skills over the enduring qualities of human
                  resilience and innovation. To break free from this, we need an education system built
                  with the fundamental understanding that these qualities are not developed through
                  standardized learning, but in spite of it.

                  We do not need a complete break from tradition, but a transformation. Imagine an
                  education system that...
                </p>
                <p>
                  <strong>Centers on Human Connection:</strong> Facilitates spaces where individual and community
                  identities are all welcomed and explored through dialogue and collaboration.
                </p>
                <p>
                  <strong>Catalyzes agency:</strong> Instead of rote learning outcomes, education focuses on developing
                  learners&#39; critical thinking, problem-solving, and innate curiosity.
                </p>
                <p>
                  <strong>Values Shared Humanity and Unique Experiences:</strong> Learning experiences that
                  elevate the understanding of our interdependence and of our collective heritage while
                  cherishing the unique individual and community journeys. This kind of education is
                  possible.
                </p>
                <p>
                  <strong>Forward looking:</strong> Imagine how unthinkable our world now was. Now, while the world
                  head exhibits the same characteristics, we can better prepare for it if we make it central
                  to our education. The development and elevation characteristics, values, and
                  dispositions necessary to succeed in a world we already know, though vaguely, will be
                  what makes education that matters in the 21 st century.
                  While challenging the status quo will not be easy, the cost of inaction far outweighs the
                  discomfort of change.
                </p>
                <p>
                  We stand on the cusp of change. Automation and AI will drastically alter the world of
                  work, shifting the source of our meaning and purpose. Will the education system adapt?
                  Our current trajectory focuses on the fulfilment of basic, tangible needs – this is

                  insufficient. We need an education system that nourishes the whole person – not just
                  the worker.

                  The education revolution will not come from institutions alone. Each of us must demand
                  more. Let us start the conversation: Share your vision: What does a truly human-
                  centered education look like to you? Support changemakers: Find organizations and
                  educators already pioneering new models. Question the norm: Challenge the idea that
                  the education we have now is the only option. This is the start of a longer conversation.
                  Stay tuned for further discussion on what this future of education might look like in
                  detail.
                </p>
              </>
            )}
          </div>

          <div>
            <Link onClick={toggleBlogReadMore}>
              {isBlogOpen ? 'Read Less' : 'Read More'}
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
