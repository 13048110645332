// NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{ fontFamily: 'Epilogue' }}>
      <h2>Page Not Found: 404</h2>
    </div>
  );
};

export default NotFoundPage;
