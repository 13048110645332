import React, { useState, useEffect, useRef } from 'react';
import Measure from 'react-measure';
import { FaEllipsisH } from 'react-icons/fa';
import { PushpinOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export default function KeyPointGridItem({ item, handleDelete, textColor, randomBackgroundColor }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    const handleMenuDropdownOpen = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const showDeleteModal = () => {
        setIsDeleteModalVisible(true);
    };

    const handleDeleteConfirm = async () => {
        console.log(item.keyPointId)
        await handleDelete(item.keyPointId);
        handleMenuDropdownOpen();
        setIsDeleteModalVisible(false);
    };

    const handleCancelDelete = () => {
        setIsDeleteModalVisible(false);
        handleMenuDropdownOpen();
    };

    const handleUpdate = () => {
        console.log(item.keyPointId)
        handleMenuDropdownOpen();
    }

    const handlePinToggle = () => {
        setIsPinned(!isPinned);
        setIsDropdownOpen(false);
    };

    return (
        <Measure>
            {({ measureRef }) => (
                <div
                    className={`grid-item ${isPinned ? 'pinned' : ''}`}
                    ref={measureRef}
                    style={{
                        backgroundColor: randomBackgroundColor,
                        color: textColor,
                    }}
                >
                    <div className='grid-tem-header' ref={dropdownRef}>
                        {isPinned && (
                            < PushpinOutlined className='menu-icon pin-icon' />
                        )}
                        <button
                            onClick={handleMenuDropdownOpen} className="ellipsis-menu"
                        >
                            <FaEllipsisH className="menu-icon" />
                        </button>
                        {isDropdownOpen && (
                            <ul className="menu-dropdown">
                                <li onClick={showDeleteModal}>
                                    <DeleteOutlined className="menu-icon delete-icon" />
                                    <span>Delete</span>
                                </li>
                                <li onClick={handleUpdate}>
                                    <FormOutlined className="menu-icon update-icon" />
                                    <span>Update</span>
                                </li>
                                <li onClick={handlePinToggle}>
                                    <PushpinOutlined className={`menu-icon ${isPinned ? 'unpin-icon' : 'pin-icon'}`} />
                                    <span>{isPinned ? 'Unpin' : 'Pin'}</span>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className='key-points-container'>
                        {item.content.split('\n').map((point, index) => (
                            <p style={{ color: textColor, }} key={index}>{point}</p>
                        ))}
                    </div>

                    <Modal
                        title="Confirm Deletion"
                        visible={isDeleteModalVisible}
                        onOk={handleDeleteConfirm}
                        onCancel={handleCancelDelete}
                        okText="Delete"
                        cancelText="Cancel"
                        okButtonProps={{ style: { backgroundColor: '#F32013', color: '#fff' } }}
                    >
                        <p style={{ color: '#000' }}>Are you sure you want to delete this item?</p>
                    </Modal>
                </div>
            )}
        </Measure>
    );
}
