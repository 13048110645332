import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useClerk } from '@clerk/clerk-react';
import { useNavigate, Link } from 'react-router-dom';
import { UserProfileSVG } from './svgs/ButtonIconsSvgs'


const UserButton = () => {
    const { user } = useUser();
    const { signOut } = useClerk();
    const [showProfile, setShowProfile] = useState(false);
    const userImage = user.profileImageUrl;
    const userName = user.fullName;
    const emailAddresses = user.emailAddresses;
    const firstEmailAddress = emailAddresses && emailAddresses.length > 0 ? emailAddresses[0].emailAddress : '';
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowProfile(false);
        }
    };

    const handleUserButtonClick = () => {
        setShowProfile(!showProfile);
    };

    const handleSignOut = () => {
        signOut(() => navigate("/"));
    };

    const handleManageAccount = () => {
        navigate('/dashboard/profile');
        setShowProfile(!showProfile);
    };

    return (
        <div style={{ display: 'inline-block', position: 'relative'}} ref={dropdownRef}>
            <button
                style={{
                    border: 'none',
                    background: 'none',
                    padding: '0',
                    margin: '0',
                    cursor: 'pointer',
                }}
                onClick={handleUserButtonClick}
            >
                <img
                    src={userImage}
                    alt={userName}
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                    }}
                />
            </button>
            {showProfile && (
                <div
                    style={{
                        position: 'absolute',
                        top: '56px',
                        right: '1rem',
                        left: 'auto',
                        background: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
                        padding: '1rem',
                        minWidth: '150px',
                        textAlign: 'left',
                        zIndex: 9999
                    }}
                >
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div>
                            <img
                                src={userImage}
                                alt={userName}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                        <div>
                            <div style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1rem", fontWeight: "600" }}>{firstEmailAddress}</div>
                            <div style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1rem", fontWeight: "600" }}>{userName}</div> </div>
                    </div>
                    <button
                        style={{
                            border: 'none',
                            color: '#000',
                            padding: '0.5rem 1rem',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            marginTop: '0.5rem',
                        }}
                        onClick={handleManageAccount}
                    >
                        Manage Account
                    </button>

                    <div style={{ borderTop: '1px #ccc solid', marginTop: '1rem' }}>
                        <button
                            style={{
                                border: 'none',
                                background: '#d9534f',
                                color: '#fff',
                                padding: '0.5rem 1rem',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                marginTop: '1rem',
                            }}
                            onClick={handleSignOut}
                        >
                            Sign Out
                        </button>
                    </div>

                </div>
            )}
        </div>
    );
};

export default UserButton;
