import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';


const ProtectedRoute = ({ children }) => {
  const { isSignedIn } = useUser();
  console.log(isSignedIn)
  const isAuthenticated = isSignedIn;

  const location = useLocation();

  return isAuthenticated ? children : <Navigate to="/auth/login" state={{ from: location }} />;
};

export default ProtectedRoute;
