import React, { useState, useEffect, useRef } from 'react';
import { FileTextOutlined, RightOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { FaEllipsisH } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DocumentCard = ({ document, onDeleteDocument }) => {
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownMenuOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsDropdownMenuOpen(!isDropdownMenuOpen);
    };

    return (
        <div key={document.documentId} className="document-card">
            <div
                className="menu-dropdown-container"
                style={{ position: 'relative' }}
                ref={dropdownRef}
            >
                <button onClick={toggleMenu} className="ellipsis-button">
                    <FaEllipsisH />
                </button>
                {isDropdownMenuOpen && (
                    <ul
                        className='dropdown-menu'
                        style={{
                            position: 'absolute',
                            top: '40px',
                            right: '10px',
                            padding: '20px',
                            position: 'absolute',
                            backgroundColor: '#f9f9f9',
                            minWidth: '160px',
                            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
                            zIndex: 99,
                            border: '1px solid #d1d1d1',
                            borderRadius: '8px'
                        }}>
                        <li
                            className='dropdown-item'
                            onClick={() => onDeleteDocument(document.documentId)}
                            style={{
                                padding: '12px 16px',
                                textDecoration: 'none',
                                color: 'black',
                                cursor: 'pointer',
                                display: 'block',
                                fontFamily: 'Epilogue',
                            }}

                        >
                            <DeleteOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                            <span>Delete</span>
                        </li>
                        <li
                            className='dropdown-item'
                            style={{
                                padding: '12px 16px',
                                textDecoration: 'none',
                                color: 'black',
                                cursor: 'pointer',
                                display: 'block',
                                fontFamily: 'Epilogue',
                            }}
                        >
                            <FormOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                            <span>Rename</span>
                        </li>
                    </ul>
                )}
            </div>
            <div className="document-card-meta">
                <FileTextOutlined className='file-text-outlined' />
                <div className='document-card-right'>
                    <div>
                        <h3 className='doc-name'>{document.name}</h3>
                    </div>
                    <div>
                        <Link to={`/dashboard/study-space/text-editor/${document.documentId}`} className="document-button">
                            Continue Writing
                            <RightOutlined style={{ marginLeft: '5px' }} />
                        </Link>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default DocumentCard;
