import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaComment } from 'react-icons/fa';
import ChatPopup from '../../components/ChatPopup';
import styled from 'styled-components';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import TextEditor from '../../components/TextEditor';
import './StudySpace.css';
import { Tooltip } from 'antd';
import { FaPaperPlane } from 'react-icons/fa';
import { Input } from 'antd';
import './TextEditorComp.css';
import LoadingIndicator from '../../components/LoadingIndicator';
const { TextArea } = Input;

const TextEditorComp = () => {
  const navigate = useNavigate();
  const [showChat, setShowChat] = useState(false);
  const { user } = useUser()
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isResponding, setIsResponding] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [text, setText] = useState('');
  const maxTextLength = 1000;
  const [greeting, setGreeting] = useState('');
  const [conversation, setConversation] = useState([]);
  const chatRef = useRef(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {

    setIsLargeScreen(window.innerWidth > 768);

    // const handleResize = () => {
    //   setIsLargeScreen(window.innerWidth > 768);
    // };

    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    let newGreeting;
    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = 'Good morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      newGreeting = 'Good afternoon';
    } else {
      newGreeting = 'Good evening';
    }

    setGreeting(newGreeting);
  }, []);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation, isResponding]);

  const CustomTooltip = ({ title, ...props }) => (
    <Tooltip
      {...props}
      title={<span style={{ color: '#fff', fontSize: '12px', fontFamily: 'Inter', fontWeight: 'bold' }}>{title}</span>}
    />
  );

  const ChatIcon = styled(FaComment)`
  font-size: 2rem;
  color: #0000FF;
  cursor: pointer;  
`;

  const handleCommentClick = () => {
    setShowChat(true);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCloseClick = () => {
    setShowChat(false);
  };

  const handleTextChange = e => {
    const newText = e.target.value;
    if (newText.length <= maxTextLength) {
      setText(newText);
    }
  };

  const handleSend = async () => {
    try {
      setIsResponding(true)

      // Add the user's query to the conversation
      setConversation(prevConversation => [
        ...prevConversation,
        { sender: 'user', text }
      ]);

      const response = await axios.post(`${apiBaseUrl}/sabrina-write?userId=${user.id}`, {
        user_query: text,
        context: editorContent
      });

      // Parse the response data
      const { comment, extendable_document_text } = JSON.parse(response.data.doc);

      // Log generated document
      // console.log('Generated document comment:', comment);
      // console.log('Generated document text:', extendable_document_text);

      // Add the bot's response to the conversation
      setConversation(prevConversation => [
        ...prevConversation,
        { sender: 'sabrina', text: comment }
      ]);

      setEditorContent(prevContent => prevContent + '\n' + extendable_document_text);

    } catch (error) {
      // console.error('Error:', error);
      // Remove the last user message from the conversation
      setConversation(prevConversation => prevConversation.slice(0, -1));
    } finally {
      setText('')
      setIsResponding(false)
    }
  };

  // 
  const toggleChat = () => {
    setIsLargeScreen(prevState => !prevState);
  };

  return (
    <div className='editor' style={{ height: "100%" }}>
      <div className='text-editor-warpper'>
        <div className='text-editor-container'>
          <TextEditor
            CustomTooltip={CustomTooltip} editorContent={editorContent} setEditorContent={setEditorContent}
          />
        </div>

        {isLargeScreen && (
          <div
            className='editor-chat-container'
            ref={chatRef}
          >
            <div style={{ width: '80%', margin: 'auto', color: '#000' }}>
              <h2 style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1.7rem", fontWeight: "900", textAlign: 'center' }}>{`${greeting}, ${user.firstName}`}</h2>

              <div style={{ padding: '0 1rem' }}>
                <p style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1rem", fontWeight: "400", textAlign: 'center', color: '#000' }}>
                  Sabrina here, your assistant. Here to provide a supportive space for all your writing needs.
                  Whether it’s drafting texts, refining essays, or polishing emails, you can count on me.
                </p>
                <p style={{ fontFamily: "Epilogue, sans-serif", fontSize: "1rem", fontWeight: "400", textAlign: 'center', color: '#000' }}>
                  I’m committed to helping you create the best possible written work.
                  So, shall we get started?
                </p>
              </div>
            </div>

            <div
              style={{
                width: '80%',
                margin: 'auto',
                backgroundColor: '#f0f0f0',
                padding: '20px',
                overflowY: 'auto',
                marginBottom: '20px'
              }}
            >
              {conversation.map((message, index) => (
                <div
                  key={index}
                  className={`message ${message.sender}`}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    flexDirection: message.sender === 'user' ? 'row-reverse' : 'row'
                  }}
                >
                  <div
                    style={{
                      maxWidth: '70%',
                      padding: '15px',
                      borderRadius: '12px',
                      backgroundColor: message.sender === 'user' ? '#0084ff' : '#ffffff',
                      color: message.sender === 'user' ? '#ffffff' : '#000000',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      marginLeft: message.sender === 'user' ? '0' : '10px',
                      marginRight: message.sender === 'user' ? '10px' : '0'
                    }}
                  >
                    <p style={{ margin: '0', whiteSpace: 'pre-wrap' }}>{message.text}</p>
                  </div>
                </div>
              ))}
            </div>

            <LoadingIndicator style={{ width: '100%', margin: '1rem' }} isLoading={isResponding} />

            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '90%', margin: 'auto', position: 'sticky', bottom: '10px' }}>
              <div className='textarea-warpper'
                style={{
                  margin: '1rem',
                  zIndex: 1,
                  width: '90%',
                  background: '#fff',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}>
                <TextArea
                  value={text}
                  onChange={handleTextChange}
                  autoSize={{ minRows: 3, maxRows: 10 }}
                  maxLength={maxTextLength}
                  placeholder='What do you want me to write for you...'
                  disabled={isResponding}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSend(e);
                    }
                  }}
                  autoFocus={false}
                  style={{
                    padding: '10px',
                    background: 'trasparent',
                    borderRadius: '0px',
                    overflowY: 'auto',
                    border: 'none',
                    resize: 'none',
                    outline: 'none',
                    MsOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    WebkitScrollbar: {
                      display: 'none'
                    }
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-end', padding: '0.5rem' }}>
                  <span>
                    <p style={{ fontSize: '0.8rem', fontWeight: 400, color: '#666' }}>{text.length} / {maxTextLength}</p>
                  </span>
                  <button
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      border: 'none',
                      borderRadius: '4px',
                      justifyContent: 'center'
                    }}
                    onClick={handleSend}
                    disabled={!text.trim() || isResponding}
                  >
                    <FaPaperPlane style={{ fontSize: '18px', verticalAlign: 'middle' }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*  */}
      {/* Toggle button for Chat Interface */}
      <button className="toggle-chat-btn" onClick={toggleChat}>
        {isLargeScreen ? 'Hide Sabrina' : 'Ask Sabrina'}
      </button>

      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "990",
        }}
      >
        <CustomTooltip
          title="Chat with your buddies"
          placement="left">
          {!showChat && <ChatIcon onClick={handleCommentClick} />}
        </CustomTooltip>

        {showChat && (
          <ChatPopup
            user={user}
            onClose={handleCloseClick}
          />
        )}
      </div>
    </div >
  );
};

export default TextEditorComp;