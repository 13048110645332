import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import './TextEditor.css';
import axios from "axios";
import { useUser } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Typography } from 'antd';
import LoadingSpin from './LoadingSpin';

const TextEditor = ({ CustomTooltip, editorContent, setEditorContent }) => {
  const { user } = useUser()
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { documentId } = useParams();
  const [documentName, setDocumentName] = useState('Untitled');
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [responseAlert, setResponseAlert] = useState(null);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get-document/${documentId}?userId=${user.id}`);
        setEditorContent(response.data.editor_content);
        setDocumentName(response.data.name);
      } catch (error) {
        setError('Error fetching DOC data');
      } finally {
        setLoading(false);
      }
    };

    fetchPdfData();
    return () => {
    };
  }, [documentId, user.id, apiBaseUrl]);

  const saveDocument = async () => {
    const userId = user.id;
    try {
      const response = await axios.put(`${apiBaseUrl}/update-text-editor-content/${documentId}?userId=${userId}`, { name: documentName, editor_content: editorContent });
      console.log(response.data);
      setDocumentName(response.data.name)
      setResponseAlert(
        <Alert
          style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="Your document is saved"
          type="success"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
    } catch (error) {
      console.error('Error Updating document:', error);
      setResponseAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px' }}
          message="An error occurred!" type="error"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
    } finally {
      setIsEditing(false)
    }
  };

  const handleEditorContentChange = (value) => {
    setEditorContent(value);
  };

  const handleFileNameChange = (event) => {
    setDocumentName(event.target.value);
    console.log(documentName)
  }

  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  if (loading) {
    return (
      <>
        <LoadingSpin />
      </>
    );
  }

  const handleRetry = () => {
    setError(null);
    setRetry(retry + 1);
  };

  if (error) {
    return (
      <div
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Typography.Text type="danger">{error}</Typography.Text>
        <Button type="primary" onClick={handleRetry} style={{ marginLeft: 8 }}>
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="text-editor"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        gap: '10px',
        flexDirection: 'column'
      }}
    >
      {responseAlert}
      <EditorToolbar saveDocument={saveDocument} CustomTooltip={CustomTooltip} isEditing={isEditing} documentName={documentName} handleFileNameChange={handleFileNameChange} setIsEditing={setIsEditing} style={{zIndex:990}} />

      <div className="editor-container"
        style={{
          overflowY: 'scroll',
          height: '100%',
          padding: '1rem',
          scrollbarWidth: 'thin',
          msOverflowStyle: 'none',
        }}
      >
        <ReactQuill
          theme="snow"
          value={editorContent}
          onChange={handleEditorContentChange}
          placeholder={"Write with Sabrina's collaboration..."}
          modules={modules}
          formats={formats}
          style={{

          }}
        />
      </div>
    </div>
  );
};

export default TextEditor;