import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Spin } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import './PdfViewer.css';
import 'react-pdf/dist/Page/TextLayer.css';

export default function PDFViewer({ onTextCopy, pdfURL }) {
    // console.log(pdfURL)

    const url = 'https://corsproxy.io/?' + encodeURIComponent(pdfURL);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [loading, setLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setLoading(false);
    }

    function handleTextSelection(event) {
        const selectedText = window.getSelection().toString();
        if (selectedText) {
            onTextCopy(selectedText);
        }
    }

    function handleZoomIn() {
        setZoomLevel(zoomLevel * 1.1);
    }

    function handleZoomOut() {
        setZoomLevel(zoomLevel / 1.1);
    }

    return (
        <div className='main'>
            <ul className='pdf-controls'>
                <li>
                    <Button onClick={handleZoomIn}>
                        <ZoomInOutlined />
                    </Button>
                </li>
                <li>
                    <Button onClick={handleZoomOut}>
                        <ZoomOutOutlined />
                    </Button>
                </li>
            </ul>
            {loading && <Spin size="large" className="loading-spinner" />}
            <div className="pdf-warpper" onCopy={handleTextSelection}>
                <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page) => {
                            return (
                                <Page
                                    key={page}
                                    scale={zoomLevel}
                                    pageNumber={page}
                                    renderTextLayer={true}
                                    renderAnnotationLayer={false}
                                />
                            );
                        })}
                </Document>
            </div>
        </div>
    );
}
