import React, { useState, useEffect, useRef } from 'react';
import { FilePdfOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';
import { FaEllipsisH } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PdfCard = ({ pdf, onDeletePdf }) => {
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownMenuOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownMenuOpen(!isDropdownMenuOpen);
    };
    return (
        <div
            className="card-item"
            key={pdf.documentId}
            style={{
                flex: 'none',
                background: '#fff',
                border: '1px solid #ccc',
                borderRadius: '5px',
                margin: '0 10px',
                width: 'calc(100% - 20px)',
            }}>

            <div style={{ position: 'relative' }} ref={dropdownRef}>
                <button
                    onClick={toggleDropdown}
                    className="ellipsis-button"
                >
                    <FaEllipsisH />
                </button>

                {isDropdownMenuOpen && (
                    <ul
                        className="dropdown-menu"
                        style={{
                            position: 'absolute',
                            top: '40px',
                            right: '10px',
                            padding: '20px',
                            position: 'absolute',
                            backgroundColor: '#f9f9f9',
                            minWidth: '160px',
                            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
                            zIndex: 99,
                            border: '1px solid #d1d1d1',
                            borderRadius: '8px'
                        }}
                    >
                        <li
                            onClick={() => onDeletePdf(pdf.documentId)}
                            className="dropdown-item"
                            style={{
                                padding: '12px 16px',
                                textDecoration: 'none',
                                color: 'black',
                                cursor: 'pointer',
                                display: 'block',
                                fontFamily: 'Epilogue',
                            }}
                        >
                            <DeleteOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Delete
                        </li>
                    </ul>
                )}

            </div>
            <div
                style={{
                    height: '180px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    padding: '2rem 1'
                }}>
                <FilePdfOutlined style={{ fontSize: '80px', color: '#0000FF', padding: '0.5rem' }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    gap: '1rem',
                    padding: '0 1rem'
                }}>
                    <h3 className='file-name'>{pdf.fileName}</h3>
                    <Link to={`/dashboard/study-space/pdf-dialogue/${pdf.documentId}`} className="document-button">
                        Continue Studying
                        <RightOutlined style={{ marginLeft: '5px' }} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PdfCard;
