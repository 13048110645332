import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import './ResourceCenter.css';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import KeyPointGridItem from './KeyPointGridItem';
import { Alert, Empty } from 'antd';
import Select from 'react-select';
import LoadingSpin from '../../components/LoadingSpin';

export default function ResourceCenter() {
  const [keyPoints, setKeyPoints] = useState([]);
  const { user } = useUser();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [responseAlert, setResponseAlert] = useState(null);
  const [filterBy, setFilterBy] = useState('all');
  const [fileNameFilter, setFileNameFilter] = useState('');
  const [loading, setLoading] = useState(false);

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getRandomStickyColor() {
    const colorPlates = [
      '#ff7eb9',
      '#ff65a3',
      '#7afcff',
      '#feff9c',
      '#fff740'
    ];
    return colorPlates[Math.floor(Math.random() * colorPlates.length)];
  }


  function getTextColor(bgColor) {
    const rgb = parseInt(bgColor.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    return luminance > 0.5 ? '#000000' : '#ffffff';
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const response = await axios.get(`${apiBaseUrl}/get-key-points`, {
          params: {
            userId: user.id,
          }
        });
        setKeyPoints(response.data);
      } catch (error) {
        console.error('Error fetching key points:', error);
      } finally {
        setLoading(false)
      }
    }

    if (apiBaseUrl && user && user.id) {
      fetchData();
    }
  }, [apiBaseUrl, user]);

  const handleDelete = async (itemId) => {
    try {
      await axios.delete(`${apiBaseUrl}/delete-key-point`, {
        params: {
          itemId: itemId,
          userId: user.id
        }
      });
      setKeyPoints(keyPoints.filter(item => item.keyPointId !== itemId));
      setResponseAlert(
        <Alert
          style={{ zIndex: 2, position: 'sticky', top: '10px', maxWidth: '400px', justifyContent: 'center', margin: 'auto' }}
          message="Successfully deleted!"
          type="success"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
      console.log("Deleted item with ID:", itemId);
    } catch (error) {
      console.error('Error deleting key point:', error);
      setResponseAlert(
        <Alert style={{ zIndex: 2, position: 'sticky', top: '10px', maxWidth: '400px', justifyContent: 'center', margin: 'auto' }}
          message="Error deleting Item!"
          type="error"
          showIcon
          closable
          onClose={() => setResponseAlert(null)}
        />
      );

      setTimeout(() => setResponseAlert(null), 5000);
    }
  };

  const handleFilterChange = (choice) => {
    if (choice.startsWith('fileName:')) {
      const fileName = choice.substring(9);
      setFileNameFilter(fileName);
      setFilterBy('fileName');
    } else {
      setFilterBy(choice);
      setFileNameFilter('');
    }
  };


  const filteredKeyPoints = keyPoints.filter(item => {
    console.log(item)
    if (filterBy === 'all') {
      return true;
    } else if (filterBy === 'recent5') {
      const fiveRecentItems = keyPoints.slice(0, 5);
      return fiveRecentItems.includes(item);
    } else if (filterBy === 'recent10') {
      const tenRecentItems = keyPoints.slice(0, 10);
      return tenRecentItems.includes(item);
    } else if (filterBy === 'recent15') {
      const fifteenRecentItems = keyPoints.slice(0, 15);
      return fifteenRecentItems.includes(item);
    } else if (filterBy === 'oldToNew') {
      const oldToNewSortedItems = keyPoints.reverse();
      return oldToNewSortedItems.indexOf(item) >= 0;
    } else {
      return item.fileName === fileNameFilter;
    }
  });

  const uniqueFileNames = Array.from(new Set(keyPoints.map(item => item.fileName)));

  const fileNameOptions = uniqueFileNames.map(fileName => ({
    value: `fileName:${fileName}`,
    label: fileName
  }));

  const options = [
    {
      value: "all",
      label: "All"
    },
    {
      value: "recent5",
      label: "Recent 5"
    },
    {
      value: "recent10",
      label: "Recent 10"
    },
    {
      value: "recent15",
      label: "Recent 15"
    },
    {
      value: "oldToNew",
      label: "From Old to New"
    },
    ...fileNameOptions
  ];

  const customStyles = {
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#808080",
    }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      padding: "0.5rem",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#0000FF" : "#fff",
      fontSize: '14px'
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#C7CEF3',
      padding: "0.2rem",
      boxShadow: "none",
      borderRadius: '30px',
      border: 'none',
      fontSize: '14px'
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: '#000' }),
  };

  const styles = {
    noKeyTakeawaysContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Epilogy',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    text: {
      fontFamily: 'Epilogue',
      fontSize: '24px',
      marginBottom: '10px',
    },
  };


  return (
    <div style={{ padding: 0, margin: 0, height: '100%' }}>
      <div
        className='key-takeaways-container-title'
      >
        <h2>Saved Key Takeaways</h2>
        <div style={{ width: '150px', maxWidth: '300px', zIndex: 2 }}>
          <Select
            defaultValue={{
              value: "all",
              label: "All"
            }}
            onChange={(choice) => handleFilterChange(choice.value)}
            options={options}
            styles={customStyles}
            searchable={false}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </div>
      </div>
      {loading && (<LoadingSpin />)}

      {(!loading && keyPoints.length === 0) && (
        <div style={styles.noKeyTakeawaysContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <div style={styles.text}>No Saved Key Takeaways</div>
            }
          />
        </div>
      )}
      {
        keyPoints.length > 0 && (
          <div className="grid-container" style={{
            border: '1px solid #ccc',
            height: 'calc(100% - 6.5rem)',
            padding: '1rem',
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
          }}>
            {/* alerts */}
            {responseAlert}

            <ResponsiveMasonry
              columnsCountBreakPoints={{ 300: 1, 480: 2, 768: 3, 1024: 4 }}
            >
              <Masonry gutter="1rem">
                {filteredKeyPoints.map((item, index) => {

                  const randomBackgroundColor = getRandomStickyColor();
                  const textColor = getTextColor(item.backgroundColor || randomBackgroundColor);

                  return (
                    <KeyPointGridItem
                      randomBackgroundColor={randomBackgroundColor}
                      textColor={textColor}
                      key={index}
                      item={item}
                      handleDelete={handleDelete}
                    />
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )
      }
    </div>
  );
}
