import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./Privacy.css"
import { Link } from "react-router-dom";

const Privacy = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);


    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
                style={{ color: '#0000FF', fontSize: '18px' }}
            >
                {label}
            </Link>
        );
    };

    return (
        <>
            <NavBar SignedIn={SignedIn} SignedOut={SignedOut} />
            <div className="privacy-section">
                <div className="privacy-section-warpper">
                    <h1 className='white-color-text-h1-tag'>
                        EDUCATION RE-IMAGINED PRIVACY POLICY
                    </h1>
                    <h3 className='white-color-text-h3-tag'>
                        1. Introduction
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We believe in earning your trust with your personal information when you use our
                        services. So, we work very hard to go above and beyond to shoulder this responsibility
                        and work very hard to protect your information. We will do all we can to put you in
                        control.
                        This document aims to explain how Education Re-Imagined collects, uses, and shares
                        user information.
                        While we are launching with just one service for now, Hlina AI, we plan on adding new
                        services and capabilities soon. In the meantime, we intend to expand the capabilities
                        and features of Hlina AI with the goal of providing you with rich and dynamic
                        experiences and capabilities to power your learning journey to great heights. For now,
                        our services include:
                        <ul>
                            <li>Hlina AI</li>
                        </ul>
                        At the moment, our services via Hlina AI can only be accessed when you have created
                        an account, agreed to the terms of service and to our privacy policy. When you sign in
                        you will be able to upload documents that are permitted and meet our service access
                        requirements. You can also expect to access our text editing space along with our AI
                        agent, Sabrina as well as AI chatbots Daniella and Robel.
                        Now, it is not uncommon to have questions or inquiries about our privacy policy so you
                        can <span> <ButtonMailto label="contact us" mailto="mailto:contactus@education21forward.com" /> </span>

                    </p>

                    <h3 className='white-color-text-h3-tag'>
                        2. Information Education Re-Imagined collects
                    </h3>
                    <p className='white-color-text-p-tag'>
                        Let us now dive into what and why we collect information as you use our services.
                        Simply put, we collect information in order to provide better services to all our users. For
                        example, you worked on a document, which could involve getting key points or
                        explanations and decided to move from that work on editing a writing. The information
                        we collect will allow us to provide you with seamless experience transitioning from one
                        workflow to another. Collecting your usage and preference information is the key to the
                        incredible service we work hard to bring to you.
                        When you create a Hlina AI account and/or sign in we collect information we store in
                        your account, which we treat as personal information. We also collect your name and
                        password. You can also choose to add an email address. We also collect the content
                        you create, upload and when relevant content you receive from our other services.
                        Here are some more details about information we collect as you use our services:

                        <ul>
                            <li>
                                Information about the apps, browsers, and devices you use to access Education
                                Re-Imagined services, which we use to update products and improve platform
                                performance.
                            </li>
                            <li>
                                Information about browser type and settings, unique identifiers, device type and
                                settings, operating system, mobile network information, application version
                                number along with the interaction of your apps, browsers, and devices with our
                                services, including IP address, crash reports, system activity, and the date, time,
                                and referrer URL of your request.
                            </li>
                            <li>
                                We collect information about your activity with our services when we use to
                                organize the dashboard. The activity information we collect may include:
                                <ul>
                                    <li>
                                        Documents you have opened.
                                    </li>
                                    <li>
                                        Chat you had with our AI agent(s) and Chatbot(s)
                                    </li>
                                    <li>
                                        Texts you have written in the text editor.
                                    </li>
                                    <li>
                                        Written work you have written.
                                    </li>
                                    <li>
                                        Terms you searched for
                                    </li>
                                    <li>
                                        Views and interactions with contents
                                    </li>
                                    <li>
                                        Materials you saved in resource enclave
                                    </li>
                                    <li>
                                        Uploaded documents
                                    </li>
                                </ul>
                            </li>
                            <li>
                                We collect location information when you use our services, which helps us offer
                                features that are location appropriate and relevant. Depending on the mediums
                                you are using to access our services Education Re-Imagined and its services as
                                well as setting you have on these mediums, we may use different types of
                                location formation to help make some services and products you use more
                                helpful and secure. These include:
                                <ul>
                                    <li>
                                        GPS and other sensor data from your device
                                    </li>
                                    <li>
                                        IP address
                                    </li>
                                    <li>
                                        Information about things near your device, such as wi-fi access points, cell
                                        towers, and Bluetooth-enabled devices
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    <p className='white-color-text-p-tag'>
                        Now let us dive deeper into our reasoning for collecting information as you use our
                        services. The information we collect is critical to:
                    </p>
                    <p className='white-color-text-p-tag'>
                        <strong>
                            Why Education Re-Imagined collects information:
                        </strong>
                        <br />
                        <ul>

                            <li>
                                Provide our services: Collecting information allows Education Re-
                                Imagined to provide its services to users, such as Hlina AI, and its AI
                                agents and chatbots. By collecting information, the company can ensure
                                that users have access to the services they need and that these services
                                function correctly.
                            </li>
                            <li>

                                Maintain and improve our services: By collecting information, Education
                                Re-Imagined can monitor the performance of its services and identify

                                areas for improvement. This allows the company to make necessary
                                updates and enhancements to ensure that its services continue to meet
                                the needs of its users.
                            </li>
                            <li>
                                Develop new services: Collecting information helps Education Re-
                                Imagined to understand the needs and preferences of its users. This
                                information can be used to develop new services that meet these needs
                                and provide additional value to users.
                            </li>
                            <li>
                                Provide customized, personalized and relevant services: By collecting
                                information about user activity and preferences, Education Re-Imagined
                                can provide customized and personalized services that are relevant to
                                each individual user. This enhances the user experience and makes the
                                services more valuable to users.
                            </li>
                            <li>
                                Measure performance: Collecting information allows Education Re-
                                Imagined to measure the performance of its services and identify areas for
                                improvement. This information can be used to make data-driven decisions
                                about how to enhance the services and provide a better experience for
                                users.
                            </li>
                            <li>
                                Interact with you directly: Collecting information allows Education Re-
                                Imagined to interact directly with its users, providing support and
                                assistance as needed. This helps to build trust and foster a positive
                                relationship between the company and its users.
                            </li>
                            <li>
                                Protect Education Re-Imagined, our users, and the public: Collecting
                                information allows Education Re-Imagined to protect its services, users,
                                and the public. This includes detecting and preventing fraud, abuse, and
                                other harmful activities, and ensuring that the services comply with
                                applicable laws and regulations.
                            </li>
                        </ul>
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        3. Your right to access, correct, and delete your personal information
                    </h3>
                    <p className='white-color-text-p-tag'>
                        While many aspects of this section have been covered in one form or another
                        throughout this policy document, we at Education Re-Imagined believe that we set
                        clearly the policy on its own section. Your personal information is yours and yours alone,
                        hence the right to have unobstructed access to it. As we grow our company, we will
                        provide you with the extensive tools and capabilities on our platform so that you have all
                        the capabilities you need to access, correct, and delete your personal information. For
                        now, just <span> <ButtonMailto label="email us" mailto="mailto:contactus@education21forward.com" /> </span> and we will personally walk
                        you through your requests.

                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        4. About sharing your information
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We will not share your personal information with companies, organizations, or
                        individuals outside of Education Re-Imagined except in the following circumstances
                        and/or cases:
                    </p>
                    <p className='white-color-text-p-tag'>
                        <strong>
                            With your consent
                        </strong>
                        <br />
                        We will only share your personal information outside of the Education Re-Imagined
                        platform when we have your consent to do so. We will always state clearly the reason
                        for asking your consent and explicitly state the reason for sharing your personal
                        information before we ask for your consent.
                    </p>
                    <p className='white-color-text-p-tag'>
                        <strong>
                            With third party service providers
                        </strong>
                        <br />
                        We provide personal information to our affiliates and other trusted businesses or
                        persons to process it for us, based on our instructions and in compliance with our
                        Privacy Policy and any other appropriate confidentiality and security measures. For
                        instance, we use service providers to house our data and use cloud-based data
                        management infrastructure to deliver our products and services, improve our internal
                        business processes, and offer additional support to customers and users. One other
                        instance is when you sign up for our services, we may use third party accounts
                        operators who provide service to our platform on collection and execution of account
                        setup.
                    </p>
                    <p className='white-color-text-p-tag'>
                        <strong>
                            For legal reasons
                        </strong>
                        <br />
                        <ul>
                            <li>
                                We will share personal information outside of Education Re-Imagined if we
                                have a good-faith belief that disclosure of the information is reasonably
                                necessary to:
                            </li>
                            <li>
                                Respond to any applicable law, regulation, legal process, or enforceable
                                governmental request.
                            </li>
                            <li>
                                Enforce applicable Terms of Service, including investigation of potential
                                violations.
                            </li>
                            <li>
                                Detect, prevent, or otherwise address fraud, security, or technical issues.
                            </li>
                            <li>
                                Protect against harm to the rights, property or safety of Education Re-
                                Imagined, our users, or the public.
                            </li>
                        </ul>

                        In the events Education Re-Imagined is involved in a merger, acquisition, or sale of
                        assets, we will continue to ensure the confidentiality of your personal information and
                        give affected users notice before personal information is transferred or become subject
                        to a different privacy policy.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        5. Protecting and securing your information
                    </h3>
                    <p className='white-color-text-p-tag'>
                        All Education Re-Imagined products are built with strong security features. We have
                        used the highest possible mechanisms to maintain and safeguard your personal
                        information as you go about using our services. In the event we detect suspicious
                        activity or potential risk to the safety and integrity of your account on our platform we will
                        notify you and help guide you through steps to stay protected.
                        <ul>
                            <li>
                                We work to protect you and Education Re-Imagined from unauthorized
                                access, alteration, infiltration, disclosure, or destruction of information we
                                maintain by
                            </li>
                            <li>
                                Using encryption technology to keep your data private while in transit.
                            </li>
                            <li>
                                We continually review and improve our information collection, storage, and
                                processing practices to prevent unauthorized access to our platform and
                                systems.
                            </li>
                        </ul>
                    </p>

                    <h3 className='white-color-text-h3-tag'>
                        6. Privacy Control
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We intend to provide you with the tools and means to control your privacy settings.
                        These tools allow you to access, review, update and delete your information, as well as
                        export and download a copy of it. While Education Re-Imagined does not currently have
                        and plan to have an advertising business process we are going above and beyond to
                        provide you with the tools necessary to choose your Ads setting. You can also control
                        what information we use to show you ads, or turn off personalized ads, by visiting My
                        Ad Center
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        7. Deleting your information
                    </h3>
                    <p className='white-color-text-p-tag'>
                        Education Re-Imagined will help facilitate the deletion of your personal information,
                        including your account on our platform. Please <span> <ButtonMailto label="contact us" mailto="mailto:deletemyinfo@education21forward.com" /> </span>
                        to facilitate this request.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        8. Data retention
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We retain the data we collect for different periods of time depending on what it is, how
                        we use it, and how you configure your settings:

                        <ul>
                            <li>
                                Some data you can delete whenever you like, such as your personal info
                                or the content you create or upload, like documents and writings.
                            </li>
                            <li>
                                Other data is deleted or anonymized automatically after a set period, such
                                usage and access logs.
                            </li>
                            <li>
                                We keep some data until you delete your Education Re-Imagined
                                Account, such as information about how often you use our services.
                            </li>
                            <li>
                                And some data we retain for longer periods of time when necessary for
                                legitimate business or legal purposes, such as security, fraud and abuse
                                prevention, or financial record-keeping.
                            </li>
                        </ul>

                        When you delete data, we follow a deletion process to make sure that your data is
                        safely and completely removed from our servers or retained only in anonymized form.
                        We try to ensure that our services protect information from accidental or malicious
                        deletion. Because of this, there may be delays between when you delete something and
                        when copies are deleted from our active and backup systems.
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        9. Compliance and cooperation with regulators
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We regularly review this Privacy Policy and make sure that we process your information
                        in ways that comply with it.
                    </p>
                    <p className='white-color-text-p-tag'>
                        <strong>
                            Data transfers
                        </strong>
                        <br />
                        We are currently using third party cloud service providers to conduct our business. That
                        usually means your data could be stored or located in a country outside of your
                        residence. Our data protection policy will remain the same iron clad regardless of the
                        location of your data.
                        Sometimes a user may make a written request and/or complaints, we will respond by
                        contacting the user. We will also work with the appropriate regulatory bodies that
                        correspond to the location of the complaint to process data transfer requests are
                        conducted in compliance with and in accordance with the relevant laws, regulations,
                        standards, and guidelines.
                    </p>
                    <p className='white-color-text-p-tag'>
                        <strong>
                            U.S. state law requirements
                        </strong>
                        <br />
                        Some U.S. state privacy laws require specific disclosures.
                        This Privacy Policy is designed to help you understand how Education Re-Imagined
                        handles your information:
                        We explain the categories of information Education Re-Imagined collects and the
                        sources of that information in Information Education Re-Imagined collects.
                        We explain the purposes for which Education Re-Imagined collects and uses
                        information in Why Education Re-Imagined collects data.
                        We explain when Education Re-Imagined may disclose information in Sharing your
                        information. Education Re-Imagined does not sell your personal information. Education
                        Re-Imagined also does not “share” your personal information as that term is defined in
                        the California Consumer Privacy Act (CCPA).
                        We explain how Education Re-Imagined retains information in Retaining your
                        information. You can also learn more about how Education Re-Imagined anonymizes
                        data. As described there, when Education Re-Imagined anonymizes data to protect

                        your privacy, we maintain policies and technical measures to avoid re-identifying that
                        information.
                    </p>
                    <p className='white-color-text-p-tag'>
                        U.S. state privacy laws also provide the right to request information about how
                        Education Re-Imagined collects, uses, and discloses your information. And they give
                        you the right to access your information, sometimes in a portable format; correct your
                        information; and to request that Education Re-Imagined delete that information. Many of
                        these laws also provide the right to opt out of certain forms of profiling and targeted
                        advertising. They also provide the right to not be discriminated against for exercising
                        these privacy rights. Additionally, the CCPA treats certain kinds of information, like
                        health data, as sensitive; when users provide this information, Education Re-Imagined
                        only uses it for purposes permitted by the CCPA, like to provide services that are
                        requested and expected by our users.
                    </p>
                    <p className='white-color-text-p-tag'>
                        The Washington My Health My Data Act and Nevada Senate Bill 370 provide specific
                        rights in addition to those rights above, that apply to health information. When you
                        provide this information, Education Re-Imagined only processes it with your consent
                        and allows you to withdraw your consent.
                    </p>
                    <p className='white-color-text-p-tag'>
                        We describe the choices you have to manage your privacy and data across Education
                        Re-Imagined’s services in Your privacy controls. These tools allow you to access,
                        review, update and delete your information, as well as export and download a copy of it.
                        You can also control what information we use to show you ads, or turn off personalized
                        ads, by visiting My Ad Center.
                    </p>
                    <p className='white-color-text-p-tag'>
                        When you use these tools, we’ll validate your request by verifying that you’re signed in
                        to your Education Re-Imagined Account. If you have questions or requests related to
                        your rights under U.S. state privacy laws, you (or your authorized agent) can also
                        contact Education Re-Imagined. And if you disagree with the decision on your request,
                        you can ask Education Re-Imagined to reconsider it by responding to our email.
                        We also provide more information on Education Re-Imagined’s handling of CCPA
                        requests.
                    </p>
                    <p className='white-color-text-p-tag'>
                        Some U.S. state privacy laws also require a description of data practices using specific
                        categories. This table uses these categories to organize the information in this Privacy
                        Policy.
                    </p>
                    <div className="pp-table">
                        <table>
                            <tr>
                                <th>Categories of
                                    information we collect</th>
                                <th>Business purposes for
                                    which information may be
                                    used or disclosed</th>
                                <th>Parties with whom
                                    information may be
                                    disclosed</th>
                            </tr>
                            <tr>
                                <td>
                                    Identifiers and similar
                                    information such as your
                                    name and password, phone number, and
                                    address, as well as
                                    unique identifiers tied to
                                    the browser, application,
                                    or device you’re using.
                                </td>
                                <td>
                                    Protecting against security
                                    threats, abuse, and illegal
                                    activity: Education Re-Imagined uses and may
                                    disclose information to
                                    detect, prevent and respond
                                    to security incidents, and for
                                    protecting against other
                                    malicious, deceptive,
                                    fraudulent, or illegal activity.
                                    For example, to protect our
                                    services, Education Re-
                                    Imagined may receive or
                                    disclose information about IP
                                    addresses that malicious
                                    actors have compromised.
                                </td>
                                <td>
                                    Other people with whom
                                    you choose to share
                                    your information, like docs or photos, videos, or
                                    chatroom interaction.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Demographic information, such as your age, gender, and language.
                                </td>
                                <td>
                                    Auditing and measurement: Education Re-Imagined uses information for analytics and measurement to understand how our services are used, as well as to fulfill obligations to our partners like publishers, advertisers, developers, or rights holders. We may disclose non-personally identifiable information publicly and with these partners, including for auditing purposes.
                                </td>
                                <td>
                                    Third parties with your consent, such as services that integrate with Education Re-Imagined’s services.
                                </td>
                            </tr>
                            <tr>
                                <td>Commercial information such as your payment information and a history of purchases you make on Education Re-Imagined’s services.</td>
                                <td>Maintaining our services: Education Re-Imagined uses information to ensure our services are working as intended, such as tracking outages or troubleshooting bugs and other issues that you report to us.</td>
                                <td>Service providers, trusted businesses, or persons that process information on Education Re-Imagined’s behalf, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.</td>
                            </tr>
                            <tr>
                                <td>Geolocation data, such as may be determined by GPS, IP address, and other data from sensors on or around your device, depending in part on your device and account settings.</td>
                                <td>Research and development: Education Re-Imagined uses information to improve our services and to develop new products, features and technologies that benefit our users and the public.</td>
                                <td>Law enforcement or other third parties, for the legal reasons described in Sharing your information.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Use of service providers: Education Re-Imagined shares information with service providers to perform services on our behalf, in compliance with our Privacy Policy and other appropriate confidentiality and security measures.
                                    For example, we may rely on service providers to help provide customer support.</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Legal reasons: Education Re-Imagined also uses information to satisfy applicable laws or regulations and discloses information in response to legal process or enforceable government requests, including to law enforcement.</td>
                                <td></td>
                            </tr>

                        </table>
                    </div>

                    <h3 className='white-color-text-h3-tag'>
                        10.	When this policy applies
                    </h3>
                    <p className='white-color-text-p-tag'>
                        This Privacy Policy applies to all of the services offered by Education Re-Imagined LLC and its affiliates.
                        This Privacy Policy doesn’t apply to services that have separate privacy policies that do not incorporate this Privacy Policy.
                        <br />
                        This Privacy Policy doesn’t apply to:
                        <ul>
                            <li>
                                The information practices of other companies and organizations that advertise our services.
                            </li>
                            <li>
                                Services offered by other companies or individuals, including products or sites they offer that may include Education Re-Imagined services to which the policy applies.
                            </li>
                        </ul>
                    </p>
                    <h3 className='white-color-text-h3-tag'>
                        11.	Changes to this policy
                    </h3>
                    <p className='white-color-text-p-tag'>
                        We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent.
                        We always indicate the date the last changes were published, and we offer access to archived versions for your review. If changes are significant, we’ll provide more prominent notice (including, for certain services, email notification of Privacy Policy changes).
                    </p>
                </div>
            </div >
            <Footer />
        </>
    );
}

export default Privacy;
