import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import axios from "axios";

function Footer() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send
      const response = await axios.post(`${apiBaseUrl}/contact`, { email, message });
      if (response.status === 200) {
        setNotification('Message sent successfully!');
        setNotificationType('success');

        setMessage('')
        setEmail('')
      } else {
        setNotification('Something went wrong. Please try again later.');
        setNotificationType('error');
      }
    } catch (error) {
      setNotification('Something went wrong. Please try again later.');
      setNotificationType('error');
    }

    // Clear notification after 3 seconds
    setTimeout(() => {
      setNotification('');
      setNotificationType('');
    }, 5000);
  };

  return (
    <footer className="footer" id="contact-section">
      <div className="container">
        <div className="column">
          <h2>Contact Us</h2>
          <div className="footer-form">
            {notification && <div className={`notification ${notificationType}`}>{notification}</div>}
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your message..."
                required
                style={{
                  resize: "vertical",
                  maxHeight: "200px",
                }}
              ></textarea>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <ul className="copyright">
          <span>
            &#169; Education Reimagined, 2024
          </span>
        </ul>
        <ul className="social-links">
          <li>
            <a href="https://www.instagram.com/hlina.ai/" target="_blank"><FaInstagram fontSize='24px' color="#808080" /></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UClfJ8bczvlBuc6hzdxAOucg/" target="_blank"><FaYoutube fontSize='24px' color="#808080" /></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/education-re-imagined/" target="_blank"><FaLinkedin fontSize='24px' color="#808080" /></a>
          </li>
        </ul>
        <ul className="privacy-terms-links">
          <li>
            <Link to='/privacy'>
              Privacy
            </Link>
          </li>
          <li>
            <Link to='/terms'>
              Terms
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
