import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 0 1rem;
  height: 100px;
  // position: sticky;
  // top: 0;
  // z-index: 999; 
  

  @media (max-width: 767px) {
    flex-direction: row;
  }
`;

const NavItem = styled.li`
  list-style: none;
  margin: 0 1rem;
  
  a {
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Epilogue', sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: #fff;
    transition: color 0.3s ease;

    &:hover {
        color: #0000FF;
    }
  }
`;

const Logo = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 767px) {
    width: 40px;
  }
`;


const MobileNavToggle = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #0000FF;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopNav = styled.ul`
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    display: none;
  }
`;

// const MobileNav = styled.ul`
//   display: none;
//   flex-direction: column;
//   margin-top: 60px;
//   padding: 0;
//   gap: 10px;
  
//   @media (max-width: 767px) {
//     display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
//   }
// `;
const MobileNav = styled.ul`
  position: absolute;
  background-color: #000;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  margin-top: 100px; 
  padding: 1rem 0;
  gap: 1rem;
  width: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px #0000FF solid;

  @media (max-width: 767px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;


const NavBar = ({ SignedIn, SignedOut }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const handleSetActive = () => {
    setIsMobileNavOpen(false);
  };

  return (
    <Nav>
      <Link to="/">
        <Logo>
          <img src='./logo192.png' alt='Logo' />
        </Logo>
      </Link>

      <div>
        <DesktopNav>
          <NavItem>
            <HashLink smooth to="/#who-we-are">
              Who we are
            </HashLink>
          </NavItem>
          <NavItem>
            <HashLink smooth to="/#features">
              Features
            </HashLink></NavItem>
          <NavItem>
            <HashLink smooth to="/#the-forward">
              The Forward
            </HashLink>
          </NavItem>
          <NavItem className='sign-up-link'>
            <SignedIn>
              <Link to="/dashboard" className="dashboard-button">Dashboard</Link>
            </SignedIn>
            <SignedOut>
              <Link to="/auth/signup" className="start-here-button">Start here</Link>
            </SignedOut>
          </NavItem>
        </DesktopNav>
        <MobileNav isOpen={isMobileNavOpen}>
          <NavItem>
            <HashLink smooth to="/#who-we-are" onClick={handleSetActive}>
              Who we are
            </HashLink>
          </NavItem>
          <NavItem>
            <HashLink smooth to="/#features" onClick={handleSetActive}>
              Features
            </HashLink></NavItem>
          <NavItem>
            <HashLink smooth to="/#the-forward" onClick={handleSetActive}>
              The Forward
            </HashLink>
          </NavItem>
          <NavItem className='sign-up-link'>
            <SignedIn>
              <Link to="/dashboard" className="dashboard-button" onClick={handleSetActive}>Dashboard</Link>
            </SignedIn>
            <SignedOut>
              <Link to="/auth/signup" className="start-here-button" onClick={handleSetActive}>Start here</Link>
            </SignedOut>
          </NavItem>
        </MobileNav>
      </div>

      <MobileNavToggle onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
        {isMobileNavOpen ? <FaTimes /> : <FaBars />}
      </MobileNavToggle>
    </Nav>
  );
};

export default NavBar;
