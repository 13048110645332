import React, { useState } from "react";
import { useSignUp } from "@clerk/clerk-react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignUpForm() {
  const { isLoaded, signUp, setActive } = useSignUp();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [pendingVerification, setPendingVerification] = useState(false);
  const [code, setCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const checkInvitationCode = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/check_invitation_code?email=${encodeURIComponent(emailAddress)}&invitation_code=${encodeURIComponent(invitationCode)}`);
      return { isInvited: true };
      // return response.data.is_invited;
    } catch (error) {
      if (error.response.status === 404) {
        return { error: "Email is not invited. Please contact us if you would like to be invited to our test list." };
      } else if (error.response.status === 403) {
        return { error: "Incorrect invitation code" };
      } else {
        return { error: "An error occurred while checking invitation code" };
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    if (!agreeToTerms) {
      setModalMessage("Please agree to the terms and privacy policy.");
      setShowModal(true);
      return;
    }

    try {
      setLoading(true);

      // Check invitation code
      const { isInvited, error } = await checkInvitationCode();
      if (!isInvited) {
        setModalMessage(error);
        setShowModal(true);
        setLoading(false);
        return;
      }

      // Proceed with signup if invitation code is valid

      await signUp.create({
        emailAddress,
        password,
        first_name,
        last_name,
      });

      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

      setPendingVerification(true);
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
      // Check for specific error messages
      if (err.errors && err.errors.length > 0) {
        const errorMessage = err.errors[0].longMessage;
        // Handle specific error messages
        if (errorMessage.includes("That email address is taken. Please try another.")) {
          setModalMessage("This email address is already registered. Please use a different one.");
        } else {
          setModalMessage(errorMessage);
        }
      } else {
        // If no specific message, display a default one
        setModalMessage("Sign-up failed. Please try again.");
      }
      // Show error modal
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const onPressVerify = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    try {
      setLoading(true);

      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });
      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });
        navigate("/dashboard");
      } else {

      }
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
      // Check for specific error messages
      if (err.errors && err.errors.length > 0) {
        const errorMessage = err.errors[0].longMessage;
        // Handle specific error messages
        if (errorMessage.includes("Incorrect code")) {
          setModalMessage("Invalid verification code. Please double-check your code.");
        } else {
          setModalMessage(errorMessage);
        }
      } else {
        // If no specific message, display a default one
        setModalMessage("Verification failed. Please try again.");
      }
      // Show error modal
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        backgroundImage: "url('/authBackground.webp')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: '1rem 0'
      }}
    >
      <div
        style={{
          width: "317px",
          padding: "20px",
          alignItems: "left",
          marginBottom: "40px",
        }}
      >
        <h2
          style={{
            fontFamily: "Epilogue, sans-serif",
            fontWeight: "Bold",
            marginBottom: "2px",
            fontSize: "64px",
            letterSpacing: "1px",
          }}
        >
          Hi!
          <br />
          Welcome
        </h2>
      </div>

      {!pendingVerification && (
        <form
          style={{
            fontFamily: "Epilogue, sans-serif",
            width: "317px",
            padding: "20px",
          }}
        >
          {/* input field for invitation code */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="text"
              id="invitation_code"
              placeholder="Invitation Code"
              value={invitationCode}
              onChange={(e) => setInvitationCode(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                border: "1px solid #BDBDBD",
              }}
            />
          </div>
          {/* ------------------- */}

          <div style={{ marginBottom: "20px" }}>
            {/* <label htmlFor="first_name">First Name</label> */}
            <input
              type="text"
              id="first_name"
              placeholder="First Name"
              required={true}
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                border: "1px solid #BDBDBD",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            {/* <label htmlFor="last_name">Last Name</label> */}
            <input
              type="text"
              id="last_name"
              placeholder="Last Name"
              required={true}
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                border: "1px solid #BDBDBD",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <input
              onChange={(e) => setEmailAddress(e.target.value)}
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                border: "1px solid #BDBDBD",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <input
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                border: "1px solid #BDBDBD",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            {/* Checkbox for agreeing to terms and conditions */}
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
                style={{ marginRight: "8px" }}
              />
              <span>
                I agree to the
                <span style={{ margin: "0 4px" }}>
                  <Link to={'/terms'}>terms</Link>
                </span>
                and
                <span style={{ margin: "0 4px" }}>
                  <Link to={'/privacy'}>privacy policy</Link>
                </span>
              </span>
            </label>
          </div>

          <button
            onClick={handleSubmit}
            disabled={loading}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#0000FF",
              color: "white",
              border: "none",
              cursor: "pointer",
              justifyContent: 'center',
            }}
          >
            {loading ? "Signing up..." : "Sign Up"}
          </button>
        </form>
      )}
      {pendingVerification && (
        <div>
          <p style={{ fontSize: "16px", color: "#333", marginBottom: "20px", fontFamily: "Epilogue, sans-serif", maxWidth: '500px' }}>
            Please Verify your email. We just sent you an email with a code.
          </p>
          <form
            style={{
              fontFamily: "Epilogue, sans-serif",
              width: "317px",
              padding: "20px",
            }}>
            <div style={{ marginBottom: "20px" }}>
              <input
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #BDBDBD",
                }}
                value={code}
                placeholder="Code..."
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <button onClick={onPressVerify} style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#0000FF",
              color: "white",
              border: "none",
              cursor: "pointer",
              justifyContent: 'center',
            }}
            >
              {loading ? "Verifing..." : "Verify Email"}
            </button>
          </form>
        </div>
      )}

      {!pendingVerification && (
        <div style={{ marginTop: "60px", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p style={{ margin: "0 10px 0 0", color: '#000' }}>Already have an account?</p>
          <Link to="/auth/login" style={{ color: "#4285F4", textDecoration: "underline", cursor: "pointer" }}>
            Login!
          </Link>
        </div>
      )}

      {pendingVerification && (
        <div style={{ marginTop: "60px", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p style={{ margin: "0 10px 0 0", cursor: "pointer", color: '#000' }} onClick={() => setPendingVerification(false)}>
            &#8592; Go back
          </p>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '400px',
              textAlign: 'center',
              color: '#000',
            }}
          >
            <p style={{ color: '#000' }}>{modalMessage}</p>
            <button onClick={() => closeModal()}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}