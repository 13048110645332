import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaTimes, FaPaperPlane, FaRedo } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';
import Select from 'react-select';

const PopupContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 400px;
  height: calc(100vh - 15rem);
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    max-width: 500px;
    width: calc(100% - 4rem);
}
`;

const CloseButton = styled(FaTimes)`
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 5px;
`;

const RetryButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #ff3333; /* Red color for retry button */
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const RetryIcon = styled(FaRedo)`
  font-size: 16px;
`;

const ChatPopup = ({
  onClose,
  user
}) => {

  const [selectedBuddy, setSelectedBuddy] = useState('daniela');
  const [message, setMessage] = useState('');
  const [hasInput, setHasInput] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [messages, setMessages] = useState([]);
  const helinaProfileImage = '/daniela.jpeg';
  const robelProfileImage = '/robel.jpeg'
  const userImage = user.profileImageUrl;
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const inputRef = useRef(null);
  const chatRef = useRef(null);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async (page = 1) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/${selectedBuddy}/messages`, {
        params: {
          user_id: user.id,
          page: page
        }
      });
      setMessages(response.data.messages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchMessages(currentPage);
  }, [user.id, currentPage, selectedBuddy]);

  const handleInputChange = (e) => {
    const limit = 2000;
    const newValue = e.target.value.slice(0, limit);
    setMessage(newValue);
    setHasInput(e.target.value.trim() !== '');
  };

  const remainingCharacters = message.length;
  const handleRetry = () => {
    setShowRetryButton(false);
    setRetryAttempts(retryAttempts + 1);
    handleSendMessage(new Event('retry'));
  };

  const handleRetryButtonClick = () => {
    setShowRetryButton(false);
    handleRetry();
  };

  const handleSendMessage = async (e) => {
    if (hasInput) {
      e.preventDefault();
      setIsSending(true);
      console.log("Message sent:", message);
      const userName = user.firstName
      const userId = user.id;

      try {
        const response = await axios.post(`${apiBaseUrl}/${selectedBuddy}?userId=${userId}`, {
          text: message,
          student_name: userName,
        });

        if (response.status === 200) {
          console.log(`responce by: ${selectedBuddy}`);
          await fetchMessages();
          setMessage('');
          setRetryAttempts(0);
          setHasInput(false);
        } else {
          console.error(`HTTP error: ${response.status}`);
          if (retryAttempts < 3) {
            setShowRetryButton(true);
          } else {
            console.error("Max retries reached. Unable to send message.");
            setMessage('');
          }
        }
      } catch (error) {
        console.error("Error sending message:", error);
        console.log(retryAttempts)
        if (retryAttempts < 3) {
          setShowRetryButton(true);
        } else {
          console.error("Max retries reached. Unable to send message.");
          setRetryAttempts(0);
          setHasInput(false);
          setMessage('');
        }
      }
      finally {
        setIsSending(false);
      }
    }
  };

  const onSendMessage = handleSendMessage;

  const options = [
    {
      value: "daniela",
      label: "Study with Daniela"
    },
    {
      value: "robel",
      label: "Study with Robel"
    }
  ];

  const customStyles = {

    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
    }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      padding: "0.5rem",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#0000FF" : "#fff",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#C7CEF3',
      padding: "0.2rem",
      boxShadow: "none",
      borderRadius: '30px',
      border: 'none'
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: '#000' }),
  };

  const handleRef = useCallback((node) => {
    if (node !== null) {
      node.focus();
    }
  }, []);


  return (
    <PopupContainer>
      <div style={{ display: 'flex', marginBottom: '1rem', background: '', alignItems: 'center', justifyContent: 'space-between' }}>
        <Select
          defaultValue={{
            value: "daniela",
            label: "Study with Daniela"
          }}
          onChange={(choice) => setSelectedBuddy(choice.value)}
          options={options}
          styles={customStyles}
          searchable={false}
          components={{
            IndicatorSeparator: () => null
          }}
        />

        <FaTimes style={{
          cursor: 'pointer',
          border: 'none',
          borderRadius: '50%',
          padding: '5px',

        }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#D3D3D3'}
          onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
          onClick={onClose} />
      </div>

      <div
        ref={chatRef}
        style={{
          height: '100%',
          overflowY: 'auto',
          padding: '0.5rem',
          marginBottom: '1rem',
          background: '#fff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          MsOverflowStyle: 'none',
          scrollbarWidth: 'none',
          WebkitScrollbar: {
            display: 'none'
          }

        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              position: 'relative',
              marginBottom: '10px',
            }}
          >

            <img
              src={message.role === 'user' ? userImage : message.name === 'Daniela' ? helinaProfileImage : robelProfileImage}
              alt={message.name}
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
            />
            <div>
              {/* <div>{message.name}</div> */}
              <div
                style={{
                  position: 'relative',
                  padding: '10px',
                  backgroundColor: message.role === 'user' ? '#e5e5ea' : '#7ad3e0',
                  color: message.role === 'user' ? '#000' : '#fff',
                  borderRadius: '10px',
                  maxWidth: message.role === 'user' ? '80%' : '100%',
                  overflowWrap: 'break-word',
                }}
              >
                {message.content}
              </div>
            </div>
          </div>


        ))}
      </div>
      <div style={{ width: '100%', height: '100px', }}>
        <form
          onSubmit={onSendMessage}
          style={{
            display: 'flex',
            background: '#fff',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            borderRadius: '10px',
            overflow: 'hidden'
          }}
        >
          <textarea
            ref={(ref) => {
              inputRef.current = ref;
              handleRef(ref);
            }}
            key="messagekey"
            type="text"
            name="message"
            placeholder='Enter Your message...'
            value={message}
            onChange={handleInputChange}
            disabled={showRetryButton || isSending}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onSendMessage(e);
              }
            }}
            style={{
              padding: '1rem',
              border: '1px solid  #D3D3D3',
              borderRadius: '5px',
              height: '80%',
              border: 'none',
              resize: 'none',
              outline: 'none',
              MsOverflowStyle: 'none',
              scrollbarWidth: 'none',
              WebkitScrollbar: {
                display: 'none'
              }
            }}
          />
          <div style={{ padding: '0.2rem', height: '20%', display: 'flex', justifyContent: 'flex-end', gap: '10px', alignItems: 'center' }}>
            <span style={{ color: '#D3D3D3', fontSize: '14px' }}>{remainingCharacters}/2000</span>

            <button
              type="submit"
              disabled={!hasInput || isSending}
              style={{
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                borderRadius: '5px',
                background: 'transparent'
              }}>
              <FaPaperPlane style={{

                borderRadius: '5px',
                padding: '0.4rem',
                border: 'none',
                width: '100%',
                height: '100%',
                color: hasInput && !isSending ? '#0000ff' : ' #D3D3D3'
              }}
                onMouseOver={(e) => e.target.style.backgroundColor = hasInput && !isSending ? ' #D3D3D3' : 'transparent'}
                onMouseOut={(e) => e.target.style.backgroundColor = hasInput && !isSending ? 'transparent' : 'transparent'} />
            </button>

          </div>
        </form>
      </div>

      {showRetryButton && (
        <RetryButton onClick={handleRetryButtonClick}>
          <RetryIcon /> Retry
        </RetryButton>
      )}
    </PopupContainer>
  );
};

export default ChatPopup;
